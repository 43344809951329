@import '~css/shared/variables';

.AppLoading {
  height: 100vh;
  width: 100vw;
  text-align: center;
  background: $background-color--canvas;

  .AppLoading__loader {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -40px 0 0 -40px;
  }
}
.ButtonAddExchange {
  display: inline-block;
  font-weight: $font-weight--bold;
  padding: 6px;
  border-radius: 0.125rem;
  cursor: pointer;
  text-decoration: none;
  position: relative;
  transition: padding 0.3s ease-in-out;
  height: 50px;
  min-width: 190px;

  &,
  &:link,
  &:visited {
    color: #fff;
    text-decoration: none;
    background: $color-atlantis;
    border: 1px solid $color-atlantis;
  }

  &:focus {
    outline: none;
  }

  &:hover {
    background: $color-sushi;
    border: 1px solid $color-atlantis;
    outline: none;
  }

  &:active {
    color: #fff;
    outline: none;
  }

  &--loading {
    cursor: progress;
  }
  &__spinner {
    position: relative;
  }
}
