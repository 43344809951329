@import '~css/shared/variables';

.waiting-liquidity-modal {
  background: #2a2b31;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
  width: 450px;
  color: #ffffff;
  border-radius: 8px;

  .waiting-lq-title {
    font-weight: 700;
    padding: 20px;
    font-size: 20px;
    text-align: center;
    background-color: #3b3c42;
    border-radius: 6px;

    .btn-icon-close {
      cursor: pointer;
    }
  }

  .waiting-lq-content {
    padding: 40px 20px;

    .spinner-wrapper {
      text-align: center;

      .Spinner {
        width: 60px;

        span {
          background-color: $color-light-atlantis;
        }
      }
    }

    .content-waiting {
      text-align: center;
      font-size: 16px;

      .content-waiting-row1 {
        margin-top: 10px;
      }

      .content-waiting-row2 {
        color: #606166;
      }
    }

    .content-success {
      font-size: 12px;
      text-align: center;
      margin-top: 10px;

      .view-on-ether {
        color: #606166;
        cursor: pointer;
      }
      .add-token-metamask {
        margin-top: 10px;
        font-size: 16px;
        cursor: pointer;
        background: linear-gradient(
          270deg,
          rgba(67, 68, 75, 0) 0%,
          #43444b 50.52%,
          rgba(67, 68, 75, 0) 100%
        );
        border-radius: 2px;
        padding: 10px;
        color: $color-light-atlantis;
        img {
          margin-left: 10px;
        }
      }
      .close-wrapper {
        margin-top: 20px;
        font-weight: 700;
        font-size: 18px;
        button {
          width: 100%;
        }
      }
    }

    .content-rejected {
      text-align: center;
      margin-top: 10px;
      .message-rejected {
        font-size: 16px;
      }
      .dismiss-wrapper {
        margin-top: 20px;
        button {
          width: 100%;
          font-weight: 700;
          font-size: 18px;
        }
      }
    }
  }
}
