@import '~css/shared/variables';

.AccountNav {
  position: relative;
  cursor: pointer;

  &:hover {
    .AccountNav__profile {
      .icon_user {
        color: #7ac231;
      }
    }
  }

  &__profile {
    display: flex;
    width: 35px;
    height: 35px;
    background-color: $color-dark-shark;
    justify-content: center;
    align-items: center;

    .icon_user {
      color: #43444b;
    }
  }

  &__avatar {
    width: 100%;
    height: 100%;
    background: url('../../../assets/user.svg') 50% 50% no-repeat;
    margin: 0rem 0rem 0 0;
  }

  &__name {
    color: $color-white;
  }

  &__subscription {
    font-size: 0.75rem;
    font-weight: $font-weight--bold;
    display: block;

    &,
    &:link,
    &:visited {
      color: $color-blue;
    }
  }

  &__menu {
    padding: 0rem 0;
    box-shadow: none;
    border-top: solid 5px transparent;
    border-radius: 2px;
    position: absolute;
    display: none;
    right: 0px;
    left: auto;
    top: 100%;
    width: 234px;
    font-size: 12px;
  }

  &__link {
    display: block;
    padding: 12px 11px;
    border-bottom: 1px solid $color-dark-mako;
    background: $color-dark-shark;

    &,
    &:link,
    &:visited {
      color: $color-white;
      text-decoration: none;
    }

    &:hover {
      background-color: $color-dark-mako;
    }

    &:first-child {
      border-radius: 2px 2px 0 0;
    }

    &:last-child {
      border: none;
      border-radius: 0 0 2px 2px;
    }
  }

  &__link-icon {
    display: inline-block;
    width: 11px;
    height: 11px;
    margin-right: 8px;
    vertical-align: middle;
    position: relative;
    background-color: $color-white;
    mask-repeat: no-repeat;
    mask-position: 50% 50%;

    &--support {
      mask-image: url('../../../assets/icons/support-1.svg');
    }

    &--account {
      mask-image: url('../../../assets/icons/account.svg');
    }

    &--logout {
      mask-image: url('../../../assets/icons/logout.svg');
    }
  }

  &:hover {
    .AccountNav__menu {
      display: block;
      z-index: $z-index--account-nav-menu;
    }
  }
}
