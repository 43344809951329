.pagingControlContainer {
  display: flex;
}

.pagingControlButton {
  display: flex;
  width: 92px;
  height: 48px;
  // background: #292b2f;
  background: #2a2b31;
  border-radius: 2px;
  cursor: pointer;
  overflow: hidden;
  text-align: center;
  line-height: 16px;
  font-size: 14px;
  margin-bottom: -1px;
  justify-content: center;
  color: rgba(255, 255, 255, 0.85);
}

.pagingControlButton:hover {
  // opacity: 0.85;
  background: rgba(122, 194, 49, 0.1);
  color: #7ac231;
}

.pagingControlButton.disableButton {
  pointer-events: none;
  opacity: 0.4;
}

.pagingControlButton + .pagingControlButton {
  margin-left: 16px;
}

.pagingControlContent {
  padding: 16px 0;
}

.pagingControlContent.prev {
  margin-left: 8px;
}

.pagingControlContent.next {
  margin-right: 8px;
}

.pagingControlContent .iconmaterial {
  height: 14px;
}
