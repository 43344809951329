@import '~css/shared/variables';

$colorTextGray: #606166;
$colorbgGray: #25262b;
$colorBgLightGray: #3b3c42;
$colorTextGreen: #7ac231;

.tooltip_liquidity {
  text-align: left;
  width: 260px;
  font-size: 12px !important;
  line-height: 16px !important;
  color: rgba(255, 255, 255, 0.5) !important;
  padding: 8px 16px !important;
  background: #484848 !important;
  .tooltip-add-sushi {
    opacity: 0.7;
    color: white;
    .title-sushi {
      opacity: 1;
      font-weight: bold;
    }
    .mt-10 {
      margin-top: 10px;
    }
  }
}

.content-container {
  .form__row {
    max-width: 100%;
  }
  .input-slippage-active {
    border: 0 !important;
  }
  .pool-container {
    display: flex;
    align-items: center;

    .label-pool {
      color: $colorTextGray;
    }

    .MuiFormGroup-root {
      margin-left: 20px;
      flex-direction: row;

      .MuiFormControlLabel-root {
        margin-bottom: 0px;
      }

      .MuiRadio-colorSecondary.Mui-checked {
        color: #7ac231;
      }

      .MuiIconButton-colorSecondary:hover {
        background-color: unset;
      }

      .MuiIconButton-root:hover {
        background-color: unset;
      }

      .MuiFormControlLabel-label {
        font-size: 14px;
        font-weight: 400;
      }
    }
  }

  .add-remove-col {
    font-size: 14px;

    .add-remove-content {
      .input__group {
        display: flex;
        align-items: center;
        div {
          width: 50%;
          color: #ffffff;
          font-size: 18px;
          background-color: $colorbgGray;
          opacity: 0.3;
          padding: 10px;
          text-align: center;
          cursor: pointer;
        }
        .active {
          background-color: $colorBgLightGray;
          color: $colorTextGreen;
          opacity: 1;
        }
      }
    }
  }
}
