@import '~css/shared/variables';

.Dashboard {
  &--Side-column {
    min-width: 334px;
    max-width: 334px;
    flex-basis: unset;
    background: $color-dark-shark;
    border-radius: 2px;
  }

  &__Holdings {
    &--stacked {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      .GridBox.GridBox--Holdings {
        width: 100%;
        min-height: 474px;
        padding: 19px 15px;

        .GridBox__headline {
          .GridBox__aside {
            min-width: auto;
            max-width: none;

            .form__checkbox-label {
              font-size: 12px;
              color: $color-dark-mid-gray;

              &:before {
                border: 1px solid $color-dark-mid-gray;
                width: 16px;
                height: 16px;
                background-color: transparent;
              }
            }
          }
        }
      }
    }
    .TableList {
      .ReactTable {
        .rt-thead.-header {
          padding: 0;
          .rt-th {
            padding: 12px 0px;
          }
        }

        .rt-table.rt-overflow {
          overflow: visible;
        }

        .rt-tbody.rt-overflow {
          overflow: visible;
        }

        .rt-td.rt-overflow {
          overflow: visible;
        }
        .rt-noData {
          position: relative;
          top: -17px;
          left: 0;
          text-align: left;
        }
      }
    }
  }

  &__Chart {
    position: relative;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    &--wrapper {
      width: 100%;
      height: 474px;

      @media (min-width: $breakpoint-xl) {
        height: 474px;
      }

      @media (min-width: $breakpoint-xxl) {
        height: 474px;
      }
    }

    .chartjs-render-monitor {
      align-self: center;
    }

    .Chart__Loader {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;
      color: $color-blue--dark-faint;

      &-text {
        text-align: center;
        display: block;
        font-size: 0.875rem;
        padding: 0 0 0.5rem 0;
        color: $color-white;
      }
    }
  }

  &__OpenOrders-column {
    .GridBox {
      padding: 19px 21px;

      .TableList {
        .ReactTable {
          .rt-thead.-header {
            padding: 0;
            .rt-th {
              padding: 12px 0px;
            }
          }

          .rt-table.rt-overflow {
            overflow: visible;
          }

          .rt-tbody.rt-overflow {
            overflow: visible;
          }

          .rt-td.rt-overflow {
            overflow: visible;
          }
          .rt-noData {
            position: relative;
            top: -17px;
            left: 0;
            text-align: left;
          }
        }
      }
    }
  }
}

.Holdings__Footer {
  font-size: 10px;
  color: $color-dark-mid-gray;
  padding-top: 8px;

  .Pagination {
    float: right;

    &__Button {
      background-color: transparent;
      color: $color-white;
      height: 16px;
      width: auto;
      min-width: 16px;
      padding: 0px 5px;
      font-size: 14px;
      line-height: 16px;
      float: left;
      font-weight: $font-weight--normal;

      &.selected {
        color: $color-sushi;
      }

      &.prev {
        background: $color-sushi url('../../assets/icons/icon-pagination-priv.svg') no-repeat center
          center !important;
        font-size: 0px;
        border-radius: 2px;
        width: 16px;
        height: 16px;
        color: transparent;

        &.disabled {
          background: $color-dark-mako url('../../assets/icons/icon-pagination-priv.svg') no-repeat
            center center !important;
        }
      }

      &.next {
        background: $color-sushi url('../../assets/icons/icon-pagination-next.svg') no-repeat center
          center !important;
        font-size: 0px;
        border-radius: 2px;
        width: 16px;
        height: 16px;
        color: transparent;

        &.disabled {
          background: $color-dark-mako url('../../assets/icons/icon-pagination-next.svg') no-repeat
            center center !important;
        }
      }

      &.disabled {
        background-color: transparent !important;
      }
    }
  }
}
