.table {
  width: 100%;

  thead {
    th {
      border: none;
      color: $text-color--subtle;
      font-size: 0.9rem;
      padding: 0.5rem 1rem;

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }
    }
  }

  &__col {
    &--date {
      width: 11rem;
    }
  }

  tbody {
    td {
      border: none;
      font-size: 0.9rem;
      padding: 0.5rem 1rem;

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }
    }
  }
}
