.rc-slider {
  position: relative;
  height: 14px;
  padding: 5px 0;
  width: 100%;
  border-radius: 6px;
  touch-action: none;
}

.rc-slider,
.rc-slider * {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.rc-slider-rail {
  width: 100%;
  background-color: #e9e9e9;
}

.rc-slider-rail,
.rc-slider-track {
  position: absolute;
  height: 4px;
  border-radius: 6px;
}

.rc-slider-track,
.rc-slider-track-1 {
  background-color: #3291e9 !important;
}

.rc-slider-step {
  position: absolute;
  width: 100%;
  height: 4px;
  background: transparent;
}

.rc-slider-handle {
  position: absolute;
  margin-left: -7px;
  margin-top: -5px;
  width: 14px;
  height: 14px;
  cursor: pointer;
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: grab;
  border-radius: 50%;
  border: 2px solid #96dbfa;
  background-color: #fff;
  touch-action: pan-x;
}

.rc-slider-mark {
  position: absolute;
  top: 18px;
  left: 0;
  width: 100%;
  font-size: 12px;
}
