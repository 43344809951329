@import '~css/shared/variables';

.TopMarkets {
  flex-grow: 1;
  width: 100%;
  background: $background-color--left;
  padding: 20px;
  position: relative;

  &__placeholder {
    flex-grow: 1;
    margin: 10px;
    width: 34%;
  }

  &__Header {
    font-weight: $font-weight--bold;

    &-title {
      font-size: 16px;
      margin: 0 0 10px;
    }
  }

  &__Footer {
    font-size: 10px;
    color: $color-dark-mid-gray;

    & .Pagination {
      float: right;

      &__Button {
        background-color: transparent;
        color: $color-white;
        height: 16px;
        width: auto;
        min-width: 16px;
        padding: 0px 5px;
        font-size: 14px;
        line-height: 16px;
        float: left;
        font-weight: $font-weight--normal;

        &.selected {
          color: $color-sushi;
        }

        &.prev {
          background: $color-sushi url('../../../assets/icons/icon-pagination-priv.svg') no-repeat
            center center !important;
          font-size: 0px;
          border-radius: 2px;
          width: 16px;
          height: 16px;
          color: transparent;

          &.disabled {
            background: $color-dark-mako url('../../../assets/icons/icon-pagination-priv.svg')
              no-repeat center center !important;
          }
        }

        &.next {
          background: $color-sushi url('../../../assets/icons/icon-pagination-next.svg') no-repeat
            center center !important;
          font-size: 0px;
          border-radius: 2px;
          width: 16px;
          height: 16px;
          color: transparent;

          &.disabled {
            background: $color-dark-mako url('../../../assets/icons/icon-pagination-next.svg')
              no-repeat center center !important;
          }
        }

        &.disabled {
          background-color: transparent !important;
        }
      }
    }
  }

  & .TableList .change-price {
    padding: 3px 0;
  }

  .TableList {
    .ReactTable {
      .rt-thead {
        .rt-th {
          padding: 12px 0px;
        }
      }
    }
  }
}
