@import '~css/shared/variables';

.Wallet__Holdings {
  .rt-table.rt-overflow,
  .rt-tbody.rt-overflow,
  .rt-td.rt-overflow:last-child {
    overflow: visible;
  }

  .TableList .ReactTable .rt-thead.-header {
    padding: 0;
  }
}

.form__checkbox-label:before {
  background-color: inherit !important;
}

.Pagination {
  float: right;

  &__Button {
    background-color: transparent;
    color: $color-white;
    height: 16px;
    width: auto;
    min-width: 16px;
    padding: 0px 5px;
    font-size: 14px;
    line-height: 16px;
    float: left;
    font-weight: $font-weight--normal;

    &.selected {
      color: $color-sushi;
      background: initial;
    }

    &.prev {
      background: $color-sushi url('../../assets/icons/icon-pagination-priv.svg') no-repeat center
        center !important;
      font-size: 0px;
      border-radius: 2px;
      width: 16px;
      height: 16px;
      color: transparent;

      &.disabled {
        background: $color-dark-mako url('../../assets/icons/icon-pagination-priv.svg') no-repeat
          center center !important;
      }
    }

    &.next {
      background: $color-sushi url('../../assets/icons/icon-pagination-next.svg') no-repeat center
        center !important;
      font-size: 0px;
      border-radius: 2px;
      width: 16px;
      height: 16px;
      color: transparent;

      &.disabled {
        background: $color-dark-mako url('../../assets/icons/icon-pagination-next.svg') no-repeat
          center center !important;
      }
    }

    &.disabled {
      background-color: transparent !important;
    }
  }
}
