.Side-column {
  min-width: 322px;
  max-width: 322px;
  flex-basis: unset;
  .TableList--clickable .rt-overflow .rt-tr-group {
    padding: 0;
  }
  .rt-thead.-header {
    padding: 0 !important;
  }
  .GridBox {
    padding: 23px 21px;
    background: $background-color--left;
    &__title {
      font-weight: 500;
      font-size: 16px;
      padding: 0px;
    }
    &--transparent {
      padding: 0;
    }
    &--Exchange {
      padding: 0px;
      width: 183px;
      margin-left: auto;
      height: auto;
      margin-top: 50px;
    }
    &--Exchange {
      .GridBox__title {
        padding: 11px;
        font-weight: $font-weight--normal;
      }
    }
  }
}
