@import '~css/shared/variables';

$backgroundColor: #1e2024;
$colorGreen: #7ac231;
$borderColor: #2a2b30;
$backgroundButtonGray: #3b3c42;
$textColorGray: #606166;

.stake-container {
  display: flex;
  margin-top: 40px;

  .stake-form {
    margin-right: 10px;
    background-color: $backgroundColor;
    padding: 20px;
    border-radius: 2px;
    width: 50%;

    .stake-title {
      margin-bottom: 30px;
    }

    .green-arrow {
      color: $colorGreen;
      font-size: 30px;
    }

    .form__row {
      padding: 20px;
      border: 1px solid $borderColor;

      .form-row-input {
        display: flex;
        width: 100%;
        margin-top: 5px;
        .approve-button {
          margin-left: 20px;
          min-width: 150px;
          &:disabled {
            background-color: gray !important;
            border: 0;
          }
        }
      }

      .form-row-label {
        display: flex;
        font-size: 12px;
        line-height: 12px;
        color: $textColorGray;
      }

      .balance-stake {
        text-align: right;
        margin-left: auto;
      }

      .field-group {
        display: flex;
        width: 100%;
        .max-button {
          background-color: $backgroundButtonGray !important;
          color: $colorGreen;
          border: 0;
        }
        input {
          color: #ffffff;
        }
        /* Chrome, Safari, Edge, Opera */
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        /* Firefox */
        input[type='number'] {
          -moz-appearance: textfield;
        }
      }
    }
  }

  .stake-description {
    width: 50%;
    background-color: $backgroundColor;
    padding: 20px;
    border-radius: 2px;
    margin-left: 10px;

    .stake-description-row {
      margin-top: 10px;
      font-size: 15px;
      line-height: 25px;
      color: $textColorGray;
    }
  }

  .error-message {
    font-size: 14px;
    color: red;
    margin-top: 5px;
  }
}

.confirm-modal-container-stake {
  background-color: #2a2b31;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
  width: 400px;
  color: #ffffff;
  border-radius: 8px;
  padding: 20px;
  .image-wrapper {
    text-align: center;
    margin-top: 20px;
    img {
      width: 80px;
    }
  }
  .text-label {
    text-align: center;
    margin-top: 10px;
  }
  .button-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
    button {
      padding: 10px 20px;
      width: 100px;
    }
    .ml-5 {
      margin-left: 20px;
    }
    .cancel-button {
      background-color: gray !important;
      border: 0;
    }
  }
}
