@import '~css/shared/variables';

.confirm-supply-modal {
  background: #2a2b31;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
  width: 450px;
  color: #ffffff;
  border-radius: 8px;

  .confirm-supply-title {
    font-weight: 700;
    padding: 20px;
    font-size: 20px;
    text-align: center;
    background-color: #3b3c42;
    border-radius: 6px;
    .btn-icon-close {
      cursor: pointer;
    }
  }

  .confirm-supply-content {
    padding: 20px;

    .amount-receive {
      text-align: center;
      font-size: 24px;
      font-weight: 700;
    }
    .pool-label {
      margin-top: 10px;
      text-align: center;
      opacity: 0.7;
      font-size: 20px;
    }
    .pool-helper {
      text-align: center;
      color: #606166;
      font-size: 14px;
    }
    .pool-box-information {
      padding: 10px;
      border: 1px solid #585a61;
      border-radius: 6px;
      margin: 20px 0;
      font-size: 14px;
      .pool-box-row {
        display: flex;
        margin-top: 10px;
        .pool-row-label {
          width: 40%;
          opacity: 0.7;
        }
        .pool-row-value {
          width: 60%;
          text-align: right;
          img {
            margin-right: 10px;
            width: 30px;
          }
          span {
            word-break: break-all;
          }
        }
      }
    }
    .button-container {
      text-align: center;
      button {
        width: 100%;
      }
    }
  }
}
