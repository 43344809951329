@import '~css/shared/variables';
$col: 8.33333333%;

.Container {
  width: 100%;
  margin: 0 auto;
  padding: 0;

  @media screen and (min-width: $breakpoint-xxl) {
    padding: 0;
  }
}

.Row {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  align-content: stretch;
  margin-bottom: 1rem;
  @media screen and (min-width: $breakpoint-xxl) {
    margin-bottom: 22px;
  }
}

.Col {
  flex: 1;
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
  margin-right: 1rem;
  @media screen and (min-width: $breakpoint-xxl) {
    margin-right: 22px;
  }

  &:last-child {
    margin-right: 0;
  }

  &--overflow {
    overflow-x: hidden;
  }

  @for $i from 1 through 12 {
    &--size-#{$i} {
      flex-basis: $i * $col;
      max-width: $i * $col;
    }
  }

  &--grow {
    max-width: 100%;
  }
  &--align-right {
    text-align: right;
  }
}

.Container,
.Row,
.Col {
  &--no-margin,
  &.no-margin {
    margin: 0;
  }
  &.no-padding {
    padding: 0;
  }
}
