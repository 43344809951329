@import '~css/shared/variables';
.flex-row {
  display: flex;
}
.flex-column {
  display: flex;
  flex-direction: column;
}
.align-item-end {
  align-items: flex-end;
}

.btn {
  min-width: 150px;
  width: auto;
  padding: 0 15px;
  letter-spacing: 0.6px;
  height: 40px;
  border-radius: 2px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 16px;
  font-size: 16px;
  font-weight: 700;
  background-color: #3b3c42;
  cursor: pointer;
  font-family: $font-family--regular;
  &--success {
    background-color: #7ac231;
  }
  &--dangerus {
    opacity: 0.5;
    cursor: auto;
    box-shadow: none;
    background-color: rgb(253, 64, 64);
    border: 1px solid rgb(253, 64, 64);
    pointer-events: none;
  }
  &--disable,
  &--disable_warning {
    cursor: auto;
    color: #fff;
    background-color: #6f6f6f;
    pointer-events: none;
  }
  &--warning {
    background-color: rgb(253, 64, 64);
    border: 1px solid rgb(253, 64, 64);
  }
  .loading-approve-btn {
    width: 32px;
    height: 32px;
    margin-left: 2px;
  }
}
// .btn:disabled,
// .btn[disabled] {
//   background-color: rgb(237, 238, 242);
//   color: rgb(136, 141, 155);
// }
.body {
  padding: 26px 25px 39px 25px;
  background: #1e2024;
  border-radius: 2px;
  flex-direction: column;
  .wrap-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    img {
      cursor: pointer;
      filter: invert(24%) sepia(6%) saturate(787%) hue-rotate(195deg) brightness(93%) contrast(85%);
    }
    .select-pool {
      display: flex;
      align-items: center;
      .select-label {
        color: #606166;
        margin-right: 20px;
      }
      .MuiFormGroup-root {
        flex-direction: row;
        .MuiFormControlLabel-root {
          margin-bottom: 0px;
        }
        .MuiRadio-colorSecondary.Mui-checked {
          color: #7ac231;
        }
        .MuiIconButton-colorSecondary:hover {
          background-color: unset;
        }
        .MuiIconButton-root:hover {
          background-color: unset;
        }
        .MuiFormControlLabel-label {
          font-size: 14px;
          font-weight: 400;
        }
      }
    }
  }

  .main-swap-form {
    .from,
    .to {
      border-radius: 2px;
      border-width: 0.1px;
      border-color: #2a2b30;
      border-style: solid;
      padding: 15px 25px 23px 25px;
      display: flex;
      justify-content: space-between;
      //   flex-direction: column;
      .btn-max-input {
        height: 40px;
        width: 60px;
        line-height: 12px;
        font-size: 12px;
        text-transform: uppercase;
        color: #7ac131;
        justify-content: center;
        align-items: center;
        display: flex;
        background-color: #3b3c42;
        border-radius: 0px 2px 2px 0px;
        cursor: pointer;
        font-weight: 700;
      }
      .btn-max-disable {
        opacity: 0.1;
        pointer-events: none;
      }
      span {
        color: rgba(96, 97, 102, 1);
        font-size: 12px;
        line-height: 12px;
        margin-bottom: 10px;
      }

      .display-token-name {
        width: 150px;
        height: 40px;
        border-radius: 2px;
        background-color: #2a2b31;
        padding: 10px 10px 10px 14px;
        display: flex;
        align-items: center;
        color: white;
        font-size: 12px;
        line-height: 12px;
        cursor: pointer;
        position: relative;
        img {
          height: 14px;
          width: auto;
          object-fit: cover;
          margin-right: 5px;
        }
        .icon {
          width: 10px;
          height: 5px;
          align-items: flex-end;
          right: 10px;
          position: absolute;
          top: 12px;
          img {
            height: 100%;
            width: 100%;
          }
        }
      }
      #input-token-name,
      #output-token-name {
        margin-top: 0;
      }
    }
    .swith-swap {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      .bg-img-wrap {
        height: 60px;
        width: 60px;
        text-align: center;
        background-color: #253316;
        // background: #7ac231;
        // opacity: 0.1;
        border-radius: 2px;
      }

      img {
        padding: 17px;
      }
    }
    .MuiInput-underline:after {
      border-bottom: unset;
    }
    .MuiInput-underline:hover:not(.Mui-disabled):before {
      border-bottom: unset;
    }
    .MuiInput-underline:before {
      // content: "\00a0";
      content: none;
    }
    .MuiTextField-root {
      border-radius: 2px;
      background-color: #2a2b31;
      width: 280px;
      height: 40px;
      .MuiInputBase-root {
        width: 100%;
        height: 100%;
        border: unset;
        input {
          padding-left: 14px;
          padding-right: 10px;
          color: white;
          font-size: 12px;
          line-height: 12px;
        }
      }
    }
    .token-input-place,
    .token-output-place {
      width: 340px;
    }
  }

  .more-info {
    .title-header {
      line-height: 18px;
      height: 18px;
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 15px;
    }
    .left,
    .right {
      border-width: 0.1px;
      border-color: #2a2b30;
      border-style: solid;
      .left__title {
        img {
          width: 24px;
          height: 24px;
          margin-right: 7px;
          border-radius: 2px;
        }
        span {
          font-size: 16px;
          line-height: 16px;
          color: white;
          letter-spacing: 0.4px;
        }

        display: flex;
        justify-content: center;
        align-items: center;

        margin-bottom: 23px;
      }
    }
    .left {
      border-radius: 2px 0 0 2px;
      margin-right: 0;
      border-right: unset;
      padding: 27px;
      .item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-right: 0.1px #2a2b30 solid;
        &:last-child {
          border-right: none;
        }
        .above {
          font-size: 14px;
          line-height: 14px;
          color: white;
          margin-bottom: 7px;
        }
        .below {
          font-size: 12px;
          line-height: 12px;
          color: #bfbebe;
        }
      }
    }
    .right {
      border-radius: 0px 2px 2px 0;
      padding: 20px 25px 20px 29px;
      .wrap {
        .item {
          margin-bottom: 3px;
          display: flex;
          justify-content: space-between;
          &:last-child {
            margin-bottom: 0;
          }
          .property,
          .value {
            color: #fff;
            line-height: 30px;
            font-size: 12px;
          }
          .value-waring {
            color: rgb(253, 64, 64);
          }

          .property {
            color: #bfbebe;
            img {
              margin-left: 6px;
              margin-bottom: 3px;
            }
          }
        }
      }
      .route-list {
        display: flex;
        align-items: center;
        .token-name {
          color: #fff;
          line-height: 12px;
          font-size: 12px;
          font-weight: $font-weight--normal;
          font-family: $font-family--regular;
        }
        img {
          width: 20px;
        }
      }
    }
  }
  .footer-swap {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .btn-clear-all {
      margin-top: 15px;
      font-size: 12px;
      line-height: 12px;
      color: #7ac231;
      letter-spacing: 0.4px;
      cursor: pointer;
    }
  }

  .text-tooltip {
    font-size: 26px;
    color: #fff;
  }
}

.setting-transection {
  position: absolute;
  background: #fff;
  top: 34px;
  right: 0px;
  z-index: 10;
  background: #2a2b31;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  width: 350px;
}

@media only screen and (max-width: 1490px) {
  .token-input-place,
  .token-output-place {
    width: 300px !important;
  }
}

@media only screen and (max-width: 1400px) {
  .token-input-place,
  .token-output-place {
    width: 210px !important;
  }
}
