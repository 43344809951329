@import '~css/shared/variables';

.AccountExchanges {
  label {
    padding-right: 50px;
    padding-left: 10px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 14px;
    color: $color-white;
  }
  input[type='radio']:checked + label:before,
  input[type='radio']:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 1px solid #2a2b31;
    border-radius: 100%;
    background: #1e2024;
  }
  &__list {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
  }

  &__item {
    margin: 0 3rem 1rem 0rem;
    padding: 0;
    position: relative;
    .AccountExchanges__label--disabled {
      color: $color-white;
    }
  }

  .active {
    &::before {
      border-radius: 2rem;
      content: '';
      background: $background-color--left;
      border: 3px solid $color-green;
      width: 16px;
      height: 16px;
      display: inline-flex;
      position: absolute;
      left: 1px;
      top: 5px;
    }
  }
  &__label {
    display: inline-block;
    text-align: left;
    font-weight: $font-weight--bold;
    font-size: 0.875rem;

    &::before {
      content: '';
      background: #1e2024;
      border: 4px solid $color-light-atlantis;
      border-radius: 2rem;
      width: 16px;
      height: 16px;
      display: inline-flex;
      margin: 0 0.5rem 0 0;
    }

    &--disabled {
      color: $color-blue--slate;
      &::before {
        background: #1e2024;
        border: 2px solid #1e2024;
      }
    }
  }
}
