.wrap-content {
  width: 373px;
  &__header {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #43444b;
    color: #fff;
    font-size: 18px;
    letter-spacing: 0.4px;
    line-height: 18px;
    height: 56px;
    padding: 20px;
    border-radius: 5px 5px 0px 0px;
    position: relative;
    img.img-right {
      position: absolute;
      right: 20px;
      top: 20px;
      cursor: pointer;
    }
    img.img-left {
      position: absolute;
      left: 17px;
      top: 16px;
      cursor: pointer;
      height: 24px;
      width: 24px;
    }
  }
  &__body {
    padding: 20px 45px;
    border-radius: 0px;
    background: #2a2b31;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
    .MuiInput-underline:after {
      border-bottom: unset;
    }
    .MuiInput-underline:hover:not(.Mui-disabled):before {
      border-bottom: unset;
    }
    .MuiInput-underline:before {
      // content: "\00a0";
      content: none;
    }
    .MuiTextField-root {
      border-radius: 2px;
      background-color: #2a2b31;
      width: 100%;
      height: 40px;
      margin-bottom: 30px;
      .MuiInputBase-root {
        width: 100%;
        height: 100%;
        border: 0.1px solid #494a52;
        border-radius: 2px;
        input {
          padding-left: 15px;
          padding-right: 30px;
          // color: #606166;
          color: #fff;
          font-size: 12px;
          line-height: 12px;
          letter-spacing: 0.4px;
          &::placeholder {
            color: #fff;
          }
        }
      }
      .MuiInputAdornment-root {
        position: absolute;
        right: 6px;
        top: 18px;
      }
    }
    .list-token {
      height: 294px;
      margin-left: -45px;
      margin-right: -45px;
      overflow-y: auto;
      .item-token-disable {
        background: #1e2024;
        opacity: 0.7;
        pointer-events: none;
      }
      .item-token {
        cursor: pointer;
        display: flex;

        &:hover {
          background-color: #38393c;
        }
        .container {
          display: flex;
          padding: 9px 45px 9px 45px;
          position: relative;
          width: 100%;
        }
        &__logo {
          width: 20px;
          height: 20px;
          margin-right: 10px;
          img {
            object-fit: contain;
            width: 100%;
            height: 100%;
          }
        }
        .wraper {
          display: flex;
          flex-direction: column;
        }
        &__symbol {
          color: #fff;
          font-size: 14px;
          line-height: 14px;
          margin-bottom: 5px;
        }
        &__name {
          color: #606166;
          font-size: 12px;
          line-height: 12px;
        }
        &__amount-token {
          line-height: 14px;
          font-size: 14px;
          position: absolute;
          right: 45px;
          color: #fff;
        }
        &__btn-import {
          line-height: 14px;
          font-size: 14px;
          position: absolute;
          right: 45px;
          color: #fff;
          background-color: red;
          padding: 5px 7px;
          border-radius: 10px;
        }
        &__btn-active {
          line-height: 14px;
          font-size: 14px;
          position: absolute;
          right: 45px;
          color: #fff;
          background-color: #7ac131;
          padding: 5px 7px;
          border-radius: 10px;
        }
      }
    }
    .close_icon_search {
      height: 14px;
      background: #fff;
      padding: 2px;
      border-radius: 50%;
      cursor: pointer;
    }
  }
  &__footer {
    background: #2a2b31;
    border-radius: 0px 0px 5px 5px;
    padding: 10px 0;
    border-top: 0.1px solid #494a52;
    .footer-content {
      width: 130px;
      margin: auto;
      text-align: center;
      cursor: pointer;
      span {
        color: #fff;
        line-height: 16px;
        font-size: 16px;
        letter-spacing: 0.6px;
      }
    }
  }
}
