.Farming-dropdown {
  background: #2a2b31;
  padding: 20px 25px 25px 50px;
  margin: 0 -25px;

  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: calc(100% / 7 * 6);
  }

  &__pair {
    margin-top: 20px;
  }

  &__title {
    font-size: 18px;
  }

  &__box {
    max-width: 400px;
    width: 400px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    box-sizing: border-box;
    border-radius: 5px;
    padding: 20px;
  }
}

.Farming-box {
  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__submitted_dialog {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;

    img {
      margin-top: 20px;
    }
    .view-on-ether {
      color: #606166;
      cursor: pointer;
      margin-top: 20px;
    }

    .close-wrapper {
      margin-top: 20px;
      text-align: center;
      margin-bottom: 20px;
    }
  }

  &__title {
    color: #606166;
    font-weight: 500;
    font-size: 14px;
  }

  &__sushi {
    margin-top: 25px;
    margin-bottom: 0;
  }

  &__dollar {
    margin-top: 10px;
    color: #606166;
    font-size: 12px;
    margin-bottom: 0;
  }

  &__button {
    padding: 10px 30px;
    background: #7ac231;
    border-radius: 3px;
    border: none;
    color: white;
    font-weight: 700;
    margin-top: 30px;
    transition: 0.2s all ease-out;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: #6fa439;
    }

    &:active {
      transform: translateY(-3px);
    }

    &:focus {
      outline: none;
    }

    &:disabled {
      background-color: #626367;
      color: #aeafb0;
    }
  }

  &__flex {
    display: flex;
    flex-direction: column;
  }
}

.Farming-info {
  &__list {
    padding-left: 0;
    margin-top: 25px;
  }

  &__item {
    list-style-type: none;

    &:not(:first-child) {
      margin-top: 15px;
    }
  }

  &__icon {
    display: inline-block;
    margin-left: 8px;
    width: 15px;
  }

  &__link {
    color: rgba(122, 194, 49, 0.5) !important;
    display: flex;
    align-items: center;
    font-weight: 500;
    text-decoration: none !important;
    transition: 0.2s all ease-out;
    cursor: pointer;

    &:hover span {
      text-decoration: underline;
    }
  }
}

.Farming-stake {
  &__button {
    padding: 12px;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 3px solid #7ac231;
    color: #7ac231;
    border-radius: 2px;
    background-color: transparent;
    cursor: pointer;

    &:not(:first-child) {
      margin-top: 10px;
    }

    &:focus {
      outline: none;
    }
  }

  &__form {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 5px;
    color: white;
    padding: 25px 20px;
    margin-top: 30px;
    font-weight: 700;
  }

  &__link {
    color: #7ac231 !important;
    display: inline-block;
    text-align: center;
    margin: 30px 0px;
    font-weight: 300 !important;
  }
}

.stake-form {
  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__header-typo {
    color: #ffffff;
    font-size: 14px;
    opacity: 0.5;
  }

  &__input {
    background-color: transparent;
    border: none;
    font-weight: 700;
    color: white;
    width: 40%;

    &::placeholder {
      color: gray;
    }

    &:focus {
      outline: none;
    }
  }

  &__max {
    background-color: transparent;
    color: #7ac131;
    background: rgba(122, 194, 49, 0.1);
    border-radius: 2px;
    border: none;
    padding: 10px 15px;
    display: inline-block;
    font-weight: 700;
    font-size: 13px;
    cursor: pointer;
    margin-right: 10px;

    &:focus {
      outline: none;
    }
  }

  &__pair {
    margin-bottom: 0;
    color: #7ac231;
    font-weight: 700;
  }
}
