// button {
//   color: red !important;
// }
.wrapper {
  table tbody {
    display: block;
    max-height: 200px;
    overflow-y: auto;
  }

  table thead,
  table tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }
}
.item-wrapper {
  display: flex;
  flex-direction: row;
}
.detail {
  width: 550px;
  max-width: 550px;

  &__img-item {
    max-width: 550px;
    width: 550px;
    max-height: 1000px;
    min-height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;

    & img {
      max-height: 100%;
      max-width: 100%;
      // object-fit: contain;
    }
    & video {
      // height: 383px;
      width: 100%;
    }
  }

  &__desc-title {
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    color: rgba(255, 255, 255, 0.85);
    padding: 20px 0;
    background: #25262b;
    border-radius: 5px 5px 0px 0px;
    margin-top: 20px;

    & svg {
      margin: 0 20px;
    }
  }
  &__inner {
    background: #1e2024;
    border-radius: 5px;
    padding: 10px 20px;
    min-height: 0px !important;
  }

  &__user-desc {
    display: flex;
    align-items: center;
    margin: 15px 0px;
  }

  &__text {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: rgba(255, 255, 255, 0.6);

    h3 {
      font-weight: bold;
      font-size: 18px;
      line-height: 18px;
      color: #7ac231;
      padding: 20px 0;
    }

    span {
      margin-left: 10px;
    }

    strong {
      font-weight: bold;
    }

    img {
      width: 30px;
      height: 30px;
      border-radius: 50%;
    }
  }
  &__panel {
    background: #2a2b31;
    border-radius: 2px;
    padding: 0 15px;
  }

  &__title {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
    padding-top: 10px;
    font-size: 12px;

    & img {
      margin-right: 15px;
      margin-bottom: 5px;
    }
  }
  &__intro {
    padding: 10px 0;
    cursor: pointer;
  }
  &__intro-desc {
    padding: 20px 0px 10px 0px;
    border-top: 1px solid #3f4045;
    transition: padding-top 0.2s ease 0s, width 0.2s ease 0s;
  }

  &__typo {
    font-size: 18px;
    line-height: 18px;
    color: rgba(255, 255, 255, 0.85);
    font-weight: bold;
  }

  &__text-desc {
    font-size: 14px;
    line-height: 20px;
    color: rgba(255, 255, 255, 0.6);
    margin: 0;
    // margin-top: 20px;
  }
  .show_menu,
  .show_list {
    display: block;
  }
  .hidden_menu,
  .hidden_list {
    display: none;
  }
  .panel-1 {
    margin-top: 20px;
  }
  &__list {
    display: flex;
    justify-content: space-between;
    padding: 3px 0;
  }
  &__chain-label {
    font-size: 14px;
    line-height: 20px;
    color: rgba(255, 255, 255, 0.6);
  }
  &__chain-info {
    font-size: 14px;
    line-height: 20px;
    color: rgba(255, 255, 255, 0.6);
  }
  .btn-icon {
    width: 40px;
    height: 40px;
    background: #4c4d55;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 25px;
  }
}
.detail-item {
  margin-left: 20px;

  &__notify {
    height: 170px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    font-size: 14px;
    line-height: 20px;
    color: rgba(255, 255, 255, 0.6);

    & span {
      margin-top: 20px;
      display: inline-block;
    }
  }
  &__empty-bids {
    width: 150px;
  }

  &__main {
    width: 100%;
    background: #1e2024;
    border-radius: 5px;
    margin-left: 20px;
    padding: 20px 20px;
  }
  &__name {
    font-weight: bold;
    font-size: 32px;
    line-height: 32px;
    color: #7ac231;
    padding-top: 20px;
  }
  &__profile {
    display: flex;
    padding: 7px 0;
    align-items: center;
    margin-bottom: 25px;
  }
  &__image {
    // margin-bottom: 17px;
    margin-right: 10px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }
  &__own {
    font-weight: normal;
    font-size: 16px;
    line-height: 16px;
    color: rgba(255, 255, 255, 0.6);
    margin-top: 0px;
    margin-bottom: 0px;
  }
  &__view {
    display: flex;
    margin-left: 30px;
    font-size: 16px;
    line-height: 16px;
    color: rgba(255, 255, 255, 0.6);
    align-items: center;
  }
  &__price {
    background: #2a2b31;
    border-radius: 2px;
    padding: 20px;
    position: relative;
    h3 {
      font-size: 14px;
      line-height: 20px;
      color: rgba(255, 255, 255, 0.6);
      margin-bottom: 15px;
    }
    p {
      margin-bottom: 5px;
    }
  }
  &__price-current {
    font-weight: bold;
    font-size: 24px;
    line-height: 20px;
    color: #ffffff;
    margin-right: 10px;
  }
  &__price-text {
    font-size: 14px;
    line-height: 20px;
    color: rgba(255, 255, 255, 0.6);
  }
  &__price-1 {
    font-size: 14px;
    line-height: 20px;
    color: rgba(255, 255, 255, 0.6);
  }
  &__buy {
    position: absolute;
    bottom: 30px;
    right: 20px;
    width: 172px;
    height: 40px;
    background: #7ac231;
    border-radius: 2px;
    font-weight: bold;
    font-size: 16px;
    line-height: 16px;
    text-align: center;
    color: #ffffff;
    border: none;
    cursor: pointer;

    &:focus {
      outline: 0;
    }
    &:hover {
      opacity: 0.85;
    }
  }
  &__offer,
  &__history {
    background: rgb(42, 43, 49);
    border-radius: 2px;
    margin-top: 20px;
    padding: 0 20px;
  }
  &__offer-tit {
    display: flex;
    justify-content: space-between;
    padding: 20px 5px;
    cursor: pointer;
  }
  &__offer-table {
    border-top: 1px solid #3f4045;
  }
  &__style-titOffer {
    img {
      margin-right: 10px;
    }
  }
  &__text-offer {
    font-weight: 600;
    font-size: 12px;
    line-height: 12px;
    color: #ffffff;
  }

  &__price-sub {
    display: flex;
    padding-top: 20px;
    border-top: 1px solid #3f4045;
  }

  &__price--avg {
    margin-left: 20px;
    & span {
      color: rgba(255, 255, 255, 0.6);
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
    }

    & p {
      font-size: 18px;
      font-weight: bold;
    }
  }
  &__group-select {
    display: flex;
    justify-content: space-between;
    width: 120px;
    height: 40px;
    background: rgba(98, 99, 103, 0.5);
    border-radius: 2px;
    padding: 10px 15px;
    cursor: pointer;
    align-items: center;
    position: relative;
  }
  &__list-item {
    background-color: #2a2b31 !important;
    border-radius: 2px;
    height: fit-content;
    margin: 0;
    z-index: 5;
    min-width: 120px;
    overflow: auto;
    position: absolute;
    background: #2a2b31;
    margin-top: 5px;
    max-height: 440px;
    overflow-y: auto;
    border-radius: 2px;

    ul {
      display: flex;
      flex-direction: column;
      background: #1e2024;
      border-radius: 2px;
      text-decoration: none;
      list-style: none;
      padding-left: 5px;
    }
    ul li {
      font-size: 12px;
      line-height: 12px;
      color: rgba(255, 255, 255, 0.85);
      padding: 15px 10px;
      height: 40px;
      cursor: pointer;
      list-style-type: none;
    }
    ul li:hover {
      background: rgba(255, 255, 255, 0.1);
    }
  }
  &__select-item {
    font-size: 12px;
    color: rgba(255, 255, 255, 0.85);
  }
}
.show_listItem,
.show_map,
.show_chart {
  display: block;
}
.hidden_listItem,
.hidden_map,
.hidden_chart {
  display: none;
}
.sale-in {
  font-size: 14px;
  line-height: 14px;
  color: rgba(255, 255, 255, 0.6);
}
.orders {
  &__inner {
    display: flex;
    padding: 17px 10px;
    align-items: center;
  }
  &__rows {
    padding: 0 10px;
    border-bottom: 1px solid #3f4045;
  }
  &__img-acc {
    // margin-bottom: 15px;
    margin-right: 15px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }
  &__address {
    font-size: 14px;
    line-height: 14px;
    color: rgba(255, 255, 255, 0.6);
    margin: 7px 0 0;
  }
  &__inner-price {
    display: flex;
  }
  &__price {
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;
  }
  &__subprice {
    font-size: 8px;
    line-height: 10px;
    color: rgba(255, 255, 255, 0.6);
  }
  .btn-cancel {
    width: 95px;
    height: 30px;
    border-radius: 2px;
    font-size: 14px;
    line-height: 14px;
    color: #7ac231 !important;
    flex: none;
    order: 0;
    flex-grow: 0;
    background: #3b3c42 !important;
    border: none;
    padding: 8px 25px;
    cursor: pointer;
    transition: 0.2s all ease-in;

    &:focus {
      outline: none;
    }

    &:active {
      transform: translateY(-3px);
    }
  }

  &__price-sub {
    select {
      background: red;
    }
  }

  &__title {
    font-size: 12px;
    line-height: 40px;
    color: #606166;
    padding: 15px 0;
    border-bottom: 1px solid #3f4045;
  }
}
.make-offer {
  width: 149px;
  height: 40px;
  background: #7ac231;
  border-radius: 2px;
  font-weight: bold;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  color: #ffffff;
  border: none;
  cursor: pointer;

  &:focus {
    outline: 0;
  }
  &:hover {
    opacity: 0.85;
  }
}
.trading {
  background: #1e2024;
  border-radius: 5px;
  margin-top: 20px;
  padding: 20px;
  position: relative;

  .trading-history {
    font-size: 12px;
    th {
      padding: 20px 0;
    }
  }
  &__inner {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #3f4045;
    padding: 10px 0;
  }
  &__head {
    img {
      width: 20px;
      margin-right: 15px;
    }
  }
  &__text-head {
    font-weight: 600;
    font-size: 12px;
    line-height: 12px;
    color: #ffffff;
  }
  &__title {
    font-size: 12px;
    line-height: 12px;
    color: #606166;
    padding: 15px 0;
    border-bottom: 1px solid #3f4045;
  }
  &__acc-img {
    margin-right: 10px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    object-fit: cover;
  }
  &__rows {
    border-bottom: 1px solid #3f4045;
  }
  &__list-name {
    font-size: 12px;
    line-height: 12px;
    color: rgba(255, 255, 255, 0.85);
    padding: 20px 0;
  }

  &__list-icon {
    margin-right: 10px;
    width: 15px;
    height: 15px;
  }
  &__filter-btn {
    display: flex;
    justify-content: space-between;
    width: 200px;
    height: 40px;
    background: #2a2b31;
    border-radius: 2px;
    align-items: center;
    padding: 0px 20px;
    cursor: pointer;
    margin-bottom: 5px;
  }
  &__filter-text {
    font-size: 12px;
    line-height: 12px;
    color: #ffffff;
  }
  .show-filter {
    display: block;
  }
  .hidden-filter {
    display: none;
  }
  &__filter-item {
    background: #2a2b31;
    border-radius: 2px;
    right: 20px;
    height: fit-content;
    margin: 0;
    z-index: 5;
    overflow: auto;
    position: absolute;
    min-width: 200px;
    margin-top: 5px;
    max-height: 440px;
    overflow-y: auto;
    border-radius: 2px;
    max-height: 200px;
    & ul {
      margin-bottom: 0;
      padding-left: 0;
    }
    & ul li {
      color: rgba(255, 255, 255, 0.85);
      display: flex;
      padding: 14px 20px;
      font-size: 12px;
      min-height: 48px;
      align-items: center;
      border-radius: 2px;
      margin-bottom: 0;
      cursor: pointer;
    }
    & ul li:hover {
      background: rgba(255, 255, 255, 0.1);
    }
  }
}

.dialog {
  .MuiDialog-paper {
    overflow-y: inherit;
    top: -120px;
  }

  &__title {
    position: relative;
  }
  &__icon {
    position: absolute !important;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    color: white !important;

    &:focus {
      outline: none;
    }
  }
  &__policy {
    margin-top: 30px;
    padding: 0px 20px;

    .MuiFormControlLabel-root {
      margin-right: 0;
      color: rgb(138, 147, 155);
    }
  }

  &__content {
    color: white;
  }

  &__label {
    margin-top: 10px;
    font-size: 17px;

    &--stretch {
      display: flex;
      margin-top: 15px;
      justify-content: space-between;
      padding: 0px 20px;
      padding-bottom: 20px;
      border-bottom: 1px solid #3f4045;
      align-items: center;

      & span {
        font-weight: bold;
        display: block;
      }
    }

    &--symmetric {
      display: flex;
      justify-content: space-between;
      margin-top: 15px;
      padding-bottom: 25px;
      border-bottom: 1px solid #3f4045;

      & span {
        font-weight: bold;
        & span:not(:first-child) {
          margin-left: 100px;
        }
      }
    }
  }

  &__input-wrapper {
    margin-top: 20px;
  }

  &__input-inner {
    display: grid;
    grid-template-columns: 3fr 1fr;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 6px;
    padding: 10px;

    & .input {
      background-color: transparent;
      border: none;
      color: white;

      &:focus {
        outline: none;
      }
    }
  }

  &__input-convert {
    text-align: right;
  }

  &__input {
    display: block;
    // padding: 10px;
    background: transparent;
    border: none;
    color: white;
    margin-left: 20px;

    &:focus {
      outline: none;
    }

    &--left {
      display: flex;
      align-items: center;

      & img {
        width: 15px;
      }
      & span {
        margin-left: 10px;
        font-weight: 600;
      }
    }
  }

  &__button {
    // width: 100%;
    background-color: #7ac231 !important;
    border-radius: 2px !important;
    padding: 8px 25px !important;
    text-align: center;
    color: white !important;
    font-size: 16px !important;
    font-weight: bold !important;
    transition: 0.2s all ease-in !important;
    cursor: pointer;

    &:focus {
      outline: none;
    }

    &:disabled {
      background-color: gray !important;
    }

    &:active {
      transform: translateY(-3px);
    }
  }

  &__block {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    align-items: center;
    border-bottom: 1px solid #3f4045;
    padding-bottom: 15px;

    &--container {
      display: flex;

      & > *:not(:first-child) {
        margin-left: 100px;
      }
    }

    &--left {
      display: flex;
      align-items: center;
      max-width: 250px;
    }

    &--right {
      text-align: right;
      min-width: 100px;
      & span {
        display: block;
      }
    }
  }

  &__annotation {
    &--normal {
      color: #08b689;
    }
    &--bold {
      font-weight: bold;
      color: #7ac231;
      font-size: 18px;
      margin-top: 5px;
    }
  }

  &__image {
    height: 100px;
    width: 100px;

    & img {
      object-fit: contain;
      width: 100%;
      height: 100%;
    }

    &-annotation {
      margin-left: 15px;
      & span {
        display: block;
      }
    }
  }

  &__actions {
    justify-content: flex-start !important;

    & button:not(:first-child) {
      margin-left: 20px;
    }
  }

  .MuiDialogActions-root {
    margin-top: 0;
  }
}

.MuiDialog-paperWidthSm {
  width: 700px;
  max-width: 700px !important;
}

.MuiDialogActions-root {
  padding: 8px 24px !important;
  margin-bottom: 25px;
}

.nice-dates {
  &:not(:last-child) {
    margin-right: 30px;
  }
}

.highlight-link {
  font-weight: bold;
}

.loading-progress {
  padding: 20px 0px;
  text-align: center;
}

.page-slider {
  &__item {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.carousel {
  .thumbs {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 0;
  }
}
.carousel-root {
  width: 100%;
}
