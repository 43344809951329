.ErrorBoundary {
  padding: 2rem 1rem;
  text-align: center;
  font-size: 1.2rem;
  width: 100%;

  &__message {
    font-size: 0.9rem;
  }
}
