@import '~css/shared/variables';

.Balance {
  &__Header {
    width: 100%;

    .Col {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }
    &.compact {
      .Col:first-of-type {
        margin-right: 40px;
      }
    }
  }
  &__Title {
    font-weight: $font-weight--bold;

    &--label {
      font-size: 16px;
      margin-bottom: 15px;
    }
    &--primary {
      font-size: 16px;
      line-height: 1;
    }
    &--secondary {
      margin-left: 16px;
      font-size: 14px;
      color: $color-dark-mid-gray;
      font-weight: $font-weight--normal;
    }

    &-available,
    &-reserved {
      font-weight: $font-weight--bold;
      &--label {
        font-size: 16px;
        color: $color-light-atlantis;
        margin-bottom: 15px;
        font-weight: $font-weight--normal;
      }
      &--primary {
        font-size: 16px;
        line-height: 1;
      }
    }

    &-reserved {
      &--label {
        color: $color-sunset-orange;
      }
    }
  }
}
