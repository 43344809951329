@import '~css/shared/variables';

.tooltip_mnemonic {
  text-align: center;
  width: 260px;
  font-size: 12px !important;
  line-height: 16px !important;
  color: rgba(255, 255, 255, 0.5) !important;
  padding: 8px 16px !important;
  background: #484848 !important;
}

.PublicArea__col {
  width: 650px !important;
}

.PublicArea {
  background: rgba(0, 0, 0, 0.7) url('./backgound-public.svg') no-repeat center center;
  background-size: cover;
  color: $text-color--main;

  &__headline__des {
    text-align: center;
    color: $color-white;
    margin-bottom: 40px;
  }

  &__headline__des__name {
    font-size: 16px;
    line-height: 16px;
    margin-bottom: 19px;
  }

  &__headline__des__name__des {
    font-weight: bold;
    font-size: 18px;
    line-height: 18px;
  }

  &__mnemonic__head {
    display: flex;
    align-items: center;
    margin-bottom: 35px;

    .mnemonic__nav {
      padding: 0px;
      margin: 0;
      background: #2a2b31;
      border-radius: 20px;
      padding: 4px;

      .item {
        height: 27px;
        min-width: 47px;
        font-size: 14px;
        color: $color-white;
        border-radius: 20px;
        background-color: transparent;
        border: none;
        outline: none;
        box-shadow: none;
        font-weight: 400;

        &.is_active {
          background-color: #ff662b;
        }
      }
    }

    .mnemonic__btn_random {
      margin-left: auto;
      font-size: 14px;
      color: #ff662b;
      border-radius: 20px;
      background-color: transparent;
      height: 27px;
      border: none;
      outline: none;
      box-shadow: none;
      text-transform: inherit;
      font-weight: 400;
      padding: 3px 0px;

      &:hover {
        background-color: transparent;
      }

      .icon_random {
        margin-right: 5px;
        font-size: 20px;
      }
    }
  }

  &__mnemonic__list__btn {
    display: flex;
    align-items: center;

    .item {
      text-transform: inherit;
      font-weight: bold;
      font-size: 14px;
      line-height: 14px;
      color: #7ac131;
      background-color: transparent;
      border: none;
      outline: none;
      box-shadow: none;
      font-weight: bold;
      padding: 0px;
      text-decoration: none;

      &:hover {
        background-color: transparent;
      }

      &.lf-right {
        margin-left: auto;
      }
    }
  }

  &__mnemonic__FormControlCheckedG {
    display: flex;
    align-items: flex-end;
    margin-top: 20px;

    .checkedG {
      width: 18px;
      height: 18px;
      margin-right: 20px;
      margin-top: 2px;

      &.Mui-checked {
        background-color: #7ac131;
      }

      .MuiSvgIcon-root {
        color: #606166;
      }
    }

    .txt {
      font-size: 12px;
      line-height: 20px;
      color: rgba(255, 255, 255, 0.5);

      .storage {
        color: $color-red--pale;
      }

      a {
        color: $color-atlantis;
        text-decoration: inherit;

        &:hover {
          color: $color-atlantis;
          text-decoration: underline;
        }
      }
    }
  }

  &__mnemonic__btn_create {
    &.MuiButtonBase-root {
      margin-top: 40px;
      text-transform: inherit;
      width: 100%;
      height: 56px;
      background: #7ac131;
      border-radius: 5px;
      font-weight: bold;
      font-size: 18px;
      line-height: 18px;
      color: #fbfcfb;
      outline: none;
      &:hover {
        background: #7ac131;
      }
      &:disabled {
        background: #2a2b31;
        color: rgba(251, 252, 251, 0.5);
      }
      &:disabled:hover {
        background: #2a2b31;
        color: rgba(251, 252, 251, 0.5);
      }
    }
  }

  &__mnemonic__list {
    display: flex;
    flex-wrap: wrap;
    margin: 0px -10px;

    .item {
      background: $color-dark-shark;
      width: calc((100% - 60px) / 3);
      margin: 0px 10px 20px 10px;
      height: 55px;
      font-size: 16px;
      line-height: 16px;
      color: $color-dark-mid-gray;
      display: block;
      align-items: center;
      padding: 20px 2px 20px 18px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &__icon__warning {
    width: 24px;
    height: 24px;
    display: block;
    background-color: $color-white;
    -webkit-mask-image: url('./icon-warning.svg');
    mask-image: url('./icon-warning.svg');
    background-size: 100%;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    display: inline-flex;
    position: relative;
    top: 6px;
    margin-left: 4px;
  }

  &__headline__intro {
    margin-bottom: 50px;
    text-align: center;
    font-size: 14px;
    line-height: 14px;
    color: rgba(255, 255, 255, 0.5);
    a {
      color: $color-atlantis !important;
    }
  }
  &__inner {
    min-height: 100vh;
    display: flex;
    align-items: center;
    padding: 2rem 0;
  }

  &__logo {
    text-align: center;
    margin: 0em 0 2rem 0;

    img {
      height: 2.25rem;
    }
  }

  &__col {
    width: 450px;
    min-height: 650px;
    max-width: 100%;
    padding: 0px 13px;
    margin: 0 auto;
  }

  &__box {
    position: relative;

    &--is-verify {
      padding: 2rem;
    }
  }

  &__headline {
    font-size: 40px;
    font-weight: $font-weight--normal;
    text-align: center;
    line-height: 40px;
    margin: 77px 0 14px 0;
  }

  &__form {
    //max-width: 300px;
    //margin: 0rem auto 0 auto;

    &--separated {
      margin-top: 1rem;
    }
  }

  &__form-hint {
    text-align: center;
    margin: 0 0 2rem 0;
  }

  &__error {
    padding: 0 0 1rem 0;
    text-align: center;
    color: $color-red;
  }

  &__hint {
    text-align: center;
    padding: 1rem 2rem;
    font-size: 1rem;
  }

  &__message {
    text-align: center;
    padding: 0rem 0;
  }

  &__loading {
    text-align: center;
    background: $background--box;
    position: absolute;
    top: 6rem;
    left: 1rem;
    right: 1rem;
    bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;

    &--alt {
      background: none;
      margin: 3rem 0 0 0;
      padding: 2rem 0;
    }
  }

  .variable {
    &--success {
      color: $color-green;
    }
  }

  &__close {
    position: absolute;
    right: 2rem;
    top: 2rem;
    width: 30px;
    height: 29px;
    display: block;
    background-color: $link-primary;
    -webkit-mask-image: url('./close-button-large.svg');
    mask-image: url('./close-button-large.svg');
    background-size: 80%;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    transition: background-color 0.3s ease-in;

    &,
    &:link,
    &:visited {
      text-decoration: none;
    }

    &:hover,
    &:active {
      background-color: $link-primary--hover;
    }
  }
}

.with-cc {
  .PublicArea__close {
    top: 4.5rem;
  }
}
