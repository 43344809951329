$backgroundColor: #1e2024;
$backgroundButtonGray: #3b3c42;

.list-liquidity-container {
  height: 100%;
  padding: 20px;
  background-color: $backgroundColor;

  .list-liquidity-title {
    font-size: 24px;
    font-weight: 700;
  }
}

.ml-5 {
  margin-left: 5px;
}
