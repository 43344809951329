@import '~css/shared/variables';

.wrap-modal-confirm {
  width: 424px;
  &__header {
    font-family: $font-family--regular;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #43444b;
    color: #fff;
    font-size: 16px;
    letter-spacing: 0.4px;
    line-height: 16px;
    height: 56px;
    padding: 20px;
    font-weight: 700;
    border-radius: 5px 5px 0px 0px;
    position: relative;
    img {
      position: absolute;
      right: 20px;
      top: 20px;
      cursor: pointer;
    }
  }
  &__body {
    padding: 32px 46px 20px 46px;
    border-radius: 0px;
    background: #2a2b31;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
    .content_in_out {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .token_in_out {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .amount {
          color: #fff;
          line-height: 42px;
          font-size: 22px;
          font-weight: $font-weight--bold;
          font-family: $font-family--regular;
          cursor: pointer;
        }
        .amount-warning {
          color: rgb(253, 64, 64);
        }

        .wrap-token-name {
          display: flex;
          align-items: center;
          img {
            height: 20px;
            width: 20px;
            object-fit: cover;
            margin-right: 10px;
          }
          .name {
            line-height: 30px;
            font-family: $font-family--regular;
            font-weight: $font-weight--normal;
            font-size: 16px;
            color: #fff;
          }
        }
      }
      .mid-icon {
        padding: 5px 0;
      }
    }
    .warning-price-update {
      font-size: 14px;
      margin-top: 13px;
      font-family: $font-family--regular;
      font-weight: $font-weight--normal;
      display: flex;
      align-items: center;
      padding: 8px 5px;
      justify-content: space-between;
      background-color: rgb(251, 235, 243);
      color: rgb(252, 86, 166);
      border-radius: 8px;
      .txt-warning {
        font-size: 16px;
        font-family: $font-family--regular;
        font-weight: $font-weight--bold;
      }
      .btn-accept {
        background-color: rgb(255, 0, 122);
        padding: 5px 8px;
        border-radius: 8px;
        color: #fff;
        font-size: 14px;
        font-family: $font-family--regular;
        font-weight: $font-weight--normal;
        cursor: pointer;
      }
    }
    .note {
      color: #606166;
      font-size: 10px;
      line-height: 15px;
      text-align: center;
      font-family: $font-family--regular;
      font-weight: $font-weight--normal;
      padding: 20px 34px 15px 34px;
    }
    .detail {
      width: auto;
      border-radius: 1px;
      border: 1px solid #ffffff1a;
      -webkit-background-clip: padding-box; /* for Safari */
      background-clip: padding-box; /* for IE9+, Firefox 4+, Opera, Chrome */
      .wrap-content-detail {
        padding: 12px 15px 18px 15px;
        .item-detail {
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: #fff;
          font-size: 12px;
          font-family: $font-family--regular;
          font-weight: $font-weight--normal;
          line-height: 30px;
          .right-warning {
            color: rgb(253, 64, 64);
          }
        }
        .change-icon {
          margin-left: 7px;
          cursor: pointer;
          img {
            width: 16px;
            height: 16px;
            margin-bottom: 2px;
          }
        }
      }
    }
  }
  &__footer {
    background: #2a2b31;
    border-radius: 0px 0px 5px 5px;
    padding: 0 46px 28px 46px;
    .btn-confirm {
      width: 100%;
      background-color: #7ac231;
      padding: 12px 0;
      color: #fff;
      text-align: center;
      font-size: 16px;
      line-height: 16px;
      font-weight: $font-weight--bold;
      font-family: $font-family--regular;
      border-radius: 2px;
      letter-spacing: 0.4px;
      cursor: pointer;
    }
    .warning {
      background-color: rgb(253, 64, 64);
      border: 1px solid rgb(253, 64, 64);
    }
    .disable {
      cursor: auto;
      color: #fff;
      background-color: #6f6f6f;
      pointer-events: none;
    }
  }
}
