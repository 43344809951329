.manage-token {
  margin-left: -25px;
  margin-right: -25px;
  .sub-tab {
    margin-bottom: 20px;
    display: flex;
    color: rgb(241, 241, 241);
    font-size: 14px;
    letter-spacing: 0.4px;
    height: 24px;
    span {
      width: 50%;
      text-align: center;
      font-weight: 600;
      cursor: pointer;
    }
    span.active {
      color: #7ac231;
      position: relative;
      &::after {
        content: '';
        position: absolute;
        background: #7ac231;
        height: 2px;
        width: 100%;
        bottom: 0;
        left: 0;
      }
    }
  }
  .body-manage {
    height: 365px;
    overflow-y: auto;
    overflow-x: hidden;
    .item-token,
    .item-token-add {
      cursor: pointer;
      display: flex;

      .container {
        display: flex;
        padding: 9px 0 9px 0;
        position: relative;
        width: 100%;
        align-items: center;
      }
      &__logo {
        width: 30px;
        height: 30px;
        margin-right: 10px;
        img {
          object-fit: contain;
          width: 100%;
          height: 100%;
        }
      }
      .wraper {
        display: flex;
        flex-direction: column;
      }
      &__symbol {
        color: #fff;
        font-size: 14px;
        line-height: 14px;
        margin-bottom: 5px;
      }
      &__name {
        color: #606166;
        font-size: 12px;
        line-height: 12px;
      }
      &__swith-btn {
        line-height: 14px;
        font-size: 14px;
        position: absolute;
        right: 0;
        color: #fff;
      }
      &__group-btn {
        line-height: 14px;
        position: absolute;
        display: flex;
        right: 0;
        .view-info {
          margin-left: 10px;
          img {
            filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(159deg) brightness(105%)
              contrast(101%);
          }
        }
        .delete,
        .view-info {
          width: 22px;
          height: 22px;
          padding: 3px;
          &:hover {
            background-color: #38393c;
          }
          img {
            height: 100%;
            width: 100%;
          }
        }
      }
    }
    .item-token:hover {
      background-color: #38393c;
    }
  }
}
