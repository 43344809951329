.container {
  display: flex;
  flex-wrap: wrap;
  padding: 16px;
}

.container-item {
  border: 1px solid;
  margin: 10px;
  width: 260px;
  height: 230px;

  .container-icon {
    font-size: 14px;
    width: 14px;
    height: 14px;
  }
  .container-img {
    width: 236px;
    height: 140px;
    text-align: center;

    & img {
      width: 100%;
      height: 140px;
    }
  }
}
