@import '~css/shared/variables';

.MarketOverview {
  &__Header {
    font-weight: $font-weight--bold;

    &-title {
      padding-top: 20px;
      font-size: 18px;
      font-weight: 500;
    }

    &-value {
      font-size: 30px;
    }

    &-secondary-title {
      color: $color-dark-mid-gray;
      font-weight: $font-weight--normal;
      font-size: 16px;
      padding-top: 20px;
    }

    &-secondary-value {
      font-size: 16px;
    }
  }

  .MarketsList {
    min-height: 440px;
  }
}

.TopMarkets__container {
  background: $background-color--left;
  margin: 0;

  .TopMarkets__Header-title {
    padding: 20px 20px 0 20px;
    .Markets__inputSearchTable {
      width: 100%;
      background-size: 20px 20px;
      border: none;
      border-radius: 0.125rem;
      padding: 10px;
      color: $color-dark-mid-gray;
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
      margin-left: auto;
      &:focus {
        outline: none;
        border-color: #355b82;
        color: $color-dark-mid-gray;
      }
    }
  }

  .TopMarkets__list {
    padding: 20px 20px 0 15px;
    display: flex;
    .quote-list {
      display: flex;
      flex-wrap: wrap;

      div {
        padding: 5px;
        color: $color-dark-mid-gray;
        align-self: center;
        cursor: pointer;
        margin-right: 5px;
      }
      .active {
        color: white;
        background-color: $color-dark-mako;
      }
    }
  }
}
