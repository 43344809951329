@import '~css/shared/variables';
.Farming {
  &__th {
    padding: 10px 20px;
    color: #606166;
    font-weight: 400;
    width: calc(100% / 7);
    display: inline-block;
  }
  &__td {
    padding: 10px 20px;
    text-align: left;
    width: calc(100% / 7);
    display: inline-block;

    &-inner {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }

  &__td-flex {
    display: flex;
    align-items: center;
  }

  &__td-flex-column {
    display: flex;
    flex-direction: column;
  }

  &__table {
    background-color: #1e2024;
    padding: 15px 25px;
    width: 100%;
    display: block;
    height: auto !important;
  }
  &__table-wrapper {
    width: 100%;
    margin-bottom: 40px;
  }
  &__thead {
    border-bottom: 1px solid #2a2b30;
    display: inline-block;
    width: 100%;
  }
  &__icon--wrapper {
    display: inline-block;
    width: 30px;
    height: 30px;
    border-radius: 2px;
    justify-content: center;
    align-items: center;
    background: #7ac231;
    margin-right: 10px;
  }

  &__text {
    color: #606166;
    margin-bottom: 0;
  }

  &__textbox {
    color: #7ac231;
    background-color: #3b3c42;
    margin-top: 10px;
    padding: 7px 10px;
    text-align: center;
    border-radius: 2px;
  }

  &__hr {
    height: 1px;
    width: 100%;
    background-color: #2a2b30;
  }

  &__icon-wrapper {
    display: inline-block;
    width: 50px;
    height: 55px;
    padding: 10px;
    margin-right: 10px;
    box-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -1px #0000000f;
    border: 1px solid rgb(209, 99, 173);
    border-radius: 4px;
  }

  &__icon {
    width: 50px;
    margin-right: 12px;

    &--sm {
      width: 30px;
    }
  }

  &__button-detail {
    font-weight: 700;
    padding: 10px 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border-radius: 5px;
    border: 4px solid #7ac231;
    color: #7ac231;
    cursor: pointer;
    transition: 0.2s all ease-out;

    &:focus {
      outline: none;
    }
  }

  &__button-detail--close {
    color: #43444b;
    border-color: #43444b;
  }

  &__tbody {
    display: inline-block;
    width: 100%;
    padding: 20px 0px;
    height: auto !important;
  }

  &__tr {
    display: flex;
    width: 100%;
    border-bottom: 1px solid #2a2b30;
  }
}
