@import '~css/shared/variables';

.AdvancedMarketForm {
  &__Tabs {
    display: flex;
    background: $color-dark-shark;
    margin: -1.2rem -1rem 0;
    border-radius: 0.25rem 0.25rem 0 0;
    align-items: center;
    font-weight: $font-weight--bold;
    user-select: none;

    &--title {
      font-size: 1.125rem;
      margin-left: 1.25rem;
    }

    &--options {
      list-style: none;
      display: flex;
      flex-grow: 1;
      justify-content: flex-end;
      margin-bottom: 0;
    }

    &--option {
      display: inline-block;
      flex-grow: 1;
      text-align: center;
      font-size: 0.75rem;
      color: $color-dark-mid-gray;
      padding: 0.75rem 0;
      cursor: pointer;
      text-transform: uppercase;
      max-width: 7rem;
      &:hover {
        background: $color-dark-mako;
      }
      &.selected {
        background: $color-dark-mako;
        color: white;
      }
    }
  }

  &--holder,
  &--holder .Col {
    display: flex;
    flex: 1 1;
    .Col {
      display: flex;
      flex: 1 1;
    }
  }

  &--trading-error {
    color: $color-blue--50;
  }
}
