@import '~css/shared/variables';

.GridBox--Exchange .GridBox__headline {
  position: relative;
  top: 0px;
  margin: 0;
  background-color: $color-dark-shark;
  .GridBox__title {
    font-size: 12px;
  }
}

.ExchangeSwitcher {
  position: relative;

  &__select {
    width: 100%;
    font-size: 12px;
    font-weight: $font-weight--normal;
    user-select: none;
  }

  &__active-exchange {
    padding: 9px;
    text-align: right;
    padding-right: 30px;
    cursor: pointer;
    font-size: 12px;
    font-weight: $font-weight--normal;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    &::after {
      content: '';
      mask-image: url('../../../assets/icons/dropdown-arrow.svg');
      background: $color-dark-mid-gray;
      width: 10px;
      height: 6px;
      display: inline-block;
      position: absolute;
      right: 13px;
      top: 15px;
    }
  }

  &__menu {
    display: none;
    padding: 0;
    list-style: none;
    z-index: $z-index--exchange-menu;
    position: relative;
  }

  &__menu-item {
    display: block;
    margin: 0;
    padding: 11px;
    background: $color-dark-shark;

    &:last-child {
      border-radius: 0 0 0.25rem 0.25rem;
    }

    &:link,
    &:visited {
      text-decoration: none;
      color: $color-white;
    }

    &:hover,
    &:active {
      background: $color-dark-mako;
    }
  }

  &__select--is-open {
    .ExchangeSwitcher__active-exchange {
      &::after {
        transform: rotate(180deg);
      }
    }

    .ExchangeSwitcher__menu {
      display: block;
      box-shadow: none;
      margin-top: 2px;
    }
  }

  &__indicator {
    position: absolute;
    left: 0.375rem;
    top: 0.8rem;
    width: 10px;
    height: 10px;
    display: block;
    border-radius: 3rem;
    border: 2px solid rgba(0, 0, 0, 0.5);
    cursor: help;

    &--none {
      background-color: $color-yellow;
    }

    &--some {
      background-color: $color-yellow;
    }

    &--all {
      background-color: $color-green;
    }
  }
}
