@import '~css/shared/variables';

.AddExchangeForm {
  .form__row:nth-of-type(2),
  .form__row:nth-of-type(3) {
    margin-top: 2rem;
  }
  &__headline {
    font-size: 1.125rem;
    font-weight: $font-weight--bold;
  }

  &__select {
    padding: 0.5rem 0 0rem;
    .is-clearable .Select-value {
      padding-top: 15px !important;
    }
  }

  &__country-hint {
    margin: 1rem 0;
    font-size: 0.875rem;
    color: $color-dark-abbey;
    p:nth-of-type(1) {
      margin-top: 2rem;
    }
    p {
      margin-top: 1rem;
    }
    a:link {
      color: $color-atlantis;
      text-decoration: none;
    }
  }
}
