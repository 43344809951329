@import '../../css/shared/variables';

.GridBox {
  border-radius: 2px;
  height: 100%;
  background: $background-color--left;
  color: $color-white;
  position: relative;
  padding: 19px 15px;
  padding-bottom: 130px;

  &--loading {
    min-height: 20rem;
  }

  &--transparent {
    background: none;
    padding: 0;
    min-height: 0;
  }

  &__headline {
    position: relative;
    margin: 0 0 21px 0;
  }

  &__title {
    margin: 0;
    font-size: 18px;
    margin: 0;
    padding: 0 0;
  }

  &__aside {
    position: absolute;
    right: 0;
    top: 0;
    min-width: 100px;
    max-width: 121px;
  }

  &__loading {
    text-align: center;
    font-size: 0.8rem;
    padding: 1rem 1rem;
    color: $color-blue--dark-faint;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);

    p {
      font-weight: $font-weight--bold;
      max-width: 10rem;
      text-align: center;
      margin: 0 auto;
    }

    &--horizontal {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: -2rem;

      p {
        margin: 0 0 0 1rem;
        text-align: left;
      }
    }
  }

  &.GridBox--Holdings {
    .GridBox__headline {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .GridBox__aside {
      position: relative;
      margin-left: 1rem;
      padding-top: 0.5rem;

      input[type='checkbox'] {
        &:checked ~ .form__checkbox-label:before {
          background-image: url("data:image/svg+xml,<%3Fxml version='1.0' encoding='UTF-8'%3F><svg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='16px' height='14px' viewBox='0 0 24 24' enable-background='new 0 0 24 24' xml:space='preserve'>  <image id='image0' width='24' height='24' x='0' y='0' xlink:href='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAQAAABKfvVzAAAABGdBTUEAALGPC/xhBQAAACBjSFJN AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QA/4ePzL8AAAAHdElN RQflAQ0GEwu35zVUAAABjElEQVQ4y42TQSiDYRjHf/s+kloSJUXKwW0HbpTNVxzktORCmqscJAex pCdJOywHB7mqJafR0k4OX1ZLUS6knCxcFCkkG+Pg/V42823P6f+8/X/f8/R/389DRSVtDHPAmeCp yO5lnz7uWWGzqqIBs/QCjUTxVTBBujmkWjWZsoA0cECXanJMGmXsENZ2SBIrAzDIlNa3zEjWFZB6 1qlVzQfzXIELIAbLdOg2wbbgCjDEpNYZpiWPGyDNrOkwc8zJzbesAjEYw0dEHguI1V/r7BB3pEcM FlnCJMWI3OnvB9nV9ms65cFpDGYJYwJ+9qRJ2VtZ0/ZXpn/sYDBDjdI9xKUJxCBCu3bESPze1LSy 9OM8wTYCVpIgC5jq5IJxeSkETsjRpw2tBBihTqczJueF6Zn2p3VEjn590oJX6w3ZKI7bBDtvp6wP /HqKU6eE7LcSAICVphh5IiSXfy9UWey8lS5YDKJs2Z//AmqxN/wqsWMm5B1cAADriGcCVHPPqFxT sop+UTEYIEBS0vxTXw+zbfyn6RiWAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDIxLTAxLTEzVDA2OjE5 OjExKzAzOjAwhy5yyAAAACV0RVh0ZGF0ZTptb2RpZnkAMjAyMS0wMS0xM1QwNjoxOToxMSswMzow MPZzynQAAAAASUVORK5CYII=' /></svg>");
        }
      }

      .form__checkbox-label {
        color: #606166;
        font-size: 11px;
        line-height: 1.5;

        &:before {
          border-radius: 0.125rem;
          background-size: contain;
          border: 1px solid #606166;
          width: 0.8rem;
          height: 0.8rem;
        }
      }
    }
  }
}
