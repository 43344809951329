@import '~css/shared/variables';
$backgroundButtonGray: #3b3c42;

.liquidity-item {
  padding: 10px;
  background-color: #2a2b31;
  margin-top: 10px;
  .liquidity-item-label {
    display: flex;
  }

  .liquidity-item-pair {
    display: flex;
    align-items: center;

    img {
      width: 20px;
    }
  }

  .liquidity-item-button {
    margin-left: auto;
    cursor: pointer;
    display: flex;

    span {
      opacity: 0.5;
      margin-right: 10px;
    }

    img {
      background-color: #2a2b31;
      width: 14px;
    }
  }

  .liquidity-item-content {
    border-top: 1px solid #3a3b40;
    margin-top: 10px;
  }

  .lq-item-row {
    display: flex;
    margin-top: 10px;
    .lq-item-label {
      opacity: 0.5;
    }
    .lq-item-value {
      margin-left: auto;
    }
  }
  .lq-button-group {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    button {
      margin: 0 10px;
      min-width: 150px;
      font-size: 18px;
      font-weight: 700;
      color: $color-light-atlantis;
      border-color: $color-light-atlantis;
      border-width: 2px;
      text-transform: capitalize;
    }
  }
}
