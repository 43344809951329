@import '~css/shared/variables';

$select-input-bg: #2a2b31;
$select-input-bg-disabled: #2a2b31;
$select-input-bg-focus: #2a2b31;
$select-input-border-color: transparent;
$select-input-border-radius: 0.25rem;
$select-input-border-focus: transparent;
$select-input-border-width: 0px;
$select-input-height: 26px;
$select-input-placeholder: $color-white;
$select-text-color: $color-white;
$select-link-hover-color: $color-white;

$select-padding-vertical: 5px;
$select-padding-horizontal: 10px;

// menu options
$select-menu-zindex: $z-index--select-menu;
$select-menu-max-height: 320px;

$select-option-bg: #2a2b31;
$select-option-focused-bg: #43444b;
$select-option-selected-bg: #2a2b31;

// clear "x" button
$select-clear-color: $color-blue--dark;
$select-clear-hover-color: $color-white;

// arrow indicator
$select-arrow-color: $color-blue--dark;
$select-arrow-color-hover: $color-white;
$select-arrow-width: 4px;

@import '~react-select/scss/default';

.Select--moon {
  font-weight: $font-weight--normal;
  font-size: 14px;
  color: $color-white;

  &-coins {
    .Select-value-label {
      &-container {
        display: flex;
        justify-content: space-between;
      }
      &-coin {
        width: 20%;
      }
      &-currency {
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin: 0 0.5rem;
        flex-grow: 1;
      }
    }
    .Select-option {
      &-container {
        font-size: 0.6875rem;
        display: flex;
        justify-content: space-between;
      }
      &-coin {
        width: 20%;
        display: inline-block;
      }
      &-currency {
        width: 33%;
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin: 0 0.5rem;
        flex-grow: 1;
      }
      &-price {
        text-align: right;
        width: 33%;
        flex-grow: 1;
      }
    }
  }

  .Select-control {
    cursor: pointer;
    background-color: $color-dark-shark;
    height: 34px;
  }

  .Select-arrow-zone {
    position: relative;
    padding-right: 2px;
    padding-top: 6px;
  }

  .Select-arrow {
    background: url('../../assets/icons/icon-select.svg');
    border: none;
    width: 19px;
    height: 19px;
    top: 0px !important;
  }

  &.has-value,
  &.is-focused,
  &.is-pseudo-focused {
    &.Select--single {
      > .Select-control {
        background-color: $color-dark-shark;
        .Select-value {
          padding: 5px 12px;
          .Select-value-label {
            color: $color-white;
          }
        }
      }
    }
  }

  &-large {
    font-size: 1rem;

    .Select-control {
      padding: 0.75rem 1rem;
      border: 1px solid $color-dark-shark;

      .Select-value,
      .Select-placeholder {
        padding: 1rem 1rem;
        color: $color-dark-abbey;
        font-weight: $font-weight--normal;
      }
    }

    .Select-menu-outer {
      border: 1px solid $color-blue--action;
      box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
    }

    .Select-option {
      font-size: 14px;
      padding: 13px 15px;
      color: $color-white;
      font-weight: $font-weight--normal;
      background: $color-dark-shark;
      border-bottom: 1px solid $color-blue--50;

      &:last-child {
        border-bottom: none;
      }

      &.is-focused {
        background: $background-color--canvas;
        border-color: $background-color--canvas;
      }
    }

    &.is-focused,
    &.is-focused:not(.is-open) {
      .Select-control {
        border: 1px solid $color-blue--action;
      }
    }

    &.has-value,
    &.is-focused,
    &.is-pseudo-focused {
      &.Select--single {
        > .Select-control {
          .Select-value {
            .Select-value-label {
              color: $color-white !important;
            }
          }
        }
      }
    }

    // force hide ugly [x] clear button
    .Select-clear-zone {
      display: none;
    }

    &.is-open {
      .Select-control {
        .Select-arrow {
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 0 0.5rem 0.5rem 0.5rem;
          border-color: transparent transparent $color-blue transparent;
        }
      }
    }
  }
}

.Select--decimal {
  font-weight: $font-weight--normal;
  font-size: 14px;
  color: $color-white;

  .Select-control {
    border: 1px solid $color-dark-shark;
    height: 34px;

    .Select-value,
    .Select-placeholder {
      padding-top: 2px;
      color: $color-dark-abbey;
      font-weight: $font-weight--normal;
    }
    .Select-arrow {
      mask-image: url('../../assets/icons/dropdown-arrow.svg');
      border: none;
      background: #606166;
      width: 10px;
      height: 6px;
      display: inline-block;
      position: absolute;
      top: 12px;
      right: 5px;
    }
  }
}

.Select--decimal.is-open {
  .Select-arrow {
    mask-image: url('../../assets/icons/dropdown-arrow.svg');
    border: none;
    background: #606166;
    width: 10px;
    height: 6px;
    display: inline-block;
    position: absolute;
    top: 12px !important;
    right: 5px;
    transform: rotate(180deg);
  }
}
