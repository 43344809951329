@import '~css/shared/variables';

.ToggleSwitch {
  height: 20px;
  width: 50px;
  background: $color-blue-disabled;
  border-radius: 1rem;
  cursor: pointer;
  position: relative;
  transition: background-color 0.3s ease;
  &:after {
    content: '';
    background: $color-blue--slate;
    height: 1rem;
    width: 1rem;
    border-radius: 1rem;
    top: 2px;
    right: 2px;
    position: absolute;
    transition: right 0.3s ease;
  }

  &--selected {
    background: $color-green;

    &.ToggleSwitch--sell {
      background: $color-red--pale;
    }

    &:after {
      right: 32px;
    }
  }
}
