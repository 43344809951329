@import '~css/shared/variables';

.AppLeft {
  &__logo {
    width: 100%;
    height: 90px;
    background: url(../../assets/logo.svg) 50% 50% no-repeat;
    margin-bottom: 55px;
  }

  .crollNavLeft {
    max-height: calc(100vh - 145px);
    overflow-y: auto;
  }

  &__nav {
    display: flex;
    flex-direction: column;
  }

  &__nav-link {
    &,
    &:link,
    &:visited {
      color: $color-white;
      text-decoration: none;
      height: 60px;
      justify-content: center;
      align-items: center;
      display: flex;
    }

    &:hover,
    &:active {
      color: $color-green;
    }

    &--is-active {
      &,
      &:link,
      &:visited {
        background: $color-dark-shark;
      }
    }
  }

  &__nav-link-icon {
    display: inline-block;
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.5rem;
    vertical-align: middle;
    position: relative;
    top: -0.1rem;
    background-color: $color-white;
    mask-repeat: no-repeat;
    mask-position: 50% 50%;

    &--dashboard {
      mask-image: url('../../assets/icons/dashboard.svg');
    }

    &--market {
      mask-image: url('../../assets/icons/market.svg');
    }

    &--wallet {
      mask-image: url('../../assets/icons/wallet.svg');
    }

    &--account {
      mask-image: url('../../assets/icons/account.svg');
    }

    &--sendtransaction {
      mask-image: url('../../assets/icons/import.svg');
    }

    &--swap {
      mask-image: url('../../assets/swap/sync.svg');
    }

    &--farming {
      mask-image: url('../../assets/farming/Union.svg');
    }

    &--stake {
      mask-image: url('../../assets/stake/paper.svg');
    }

    &--liquidity {
      mask-image: url('../../assets/liquidity/Vector.svg');
    }

    &--opensea {
      mask-image: url('../../assets/icons/opensea.svg');
    }
  }
}
