@import '~css/shared/variables';

.Pagination {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &__Button {
    background: $color-blue--slate;
    height: 20px;
    width: 20px;
    border-radius: 2px;
    display: inline-block;
    color: $color-blue--highlight;
    margin-right: 5px;
    cursor: pointer;
    text-align: center;
    padding-top: 2px;
    font-size: 0.6875rem;
    font-weight: $font-weight--bold;

    &:last-child {
      margin-right: 0;
    }

    // &:hover {
    //   background: $color-blue--dark;
    //   color: $color-blue--very-dark;
    // }

    &.selected {
      cursor: default;
      background: $color-blue--dark;
      color: $color-blue--very-dark;
    }

    &.disabled {
      cursor: default;
      background: $color-blue--slate !important;
      color: $color-blue--very-dark;
    }
  }
}
