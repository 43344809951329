.modal-success {
  background-color: #2a2b31;
  width: 450px;
  border-radius: 8px;

  .title-modal {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: 700;
    font-size: 24px;
    background-color: #43444b;
    padding: 20px;
    color: #ffffff;
    position: relative;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;

    img {
      position: absolute;
      right: 30px;
      width: 20px;
      cursor: pointer;
    }
  }

  .modal-content {
    padding: 20px 40px;

    .icon-modal {
      margin-top: 20px;
      text-align: center;
    }

    .message-modal {
      font-size: 14px;
      color: #ffffff;
      text-align: center;
      margin-top: 20px;
    }

    .close-button-modal {
      margin-top: 20px;
      text-align: center;

      button {
        color: #ffffff;
        font-size: 16px;
        font-weight: 700;
        background-color: #7ac231;
        height: 40px;
        border-radius: 2px;
        display: block;
        width: 100%;
        cursor: pointer;
        border: none;

        &:focus {
          border: 0;
          outline: 0;
        }

        &:active {
          background-color: #687657;
        }
      }
    }
  }
}
