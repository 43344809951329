@import '~css/shared/variables';

.OrderBook {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  .GridBox__aside {
    top: -2px;
  }

  &__column {
    flex: 1;
    &--bids {
      padding-top: 0.1rem;
    }
    &--asks {
      padding-left: 0rem;
    }
  }

  &__column--bids,
  &__column--asks {
    .rt-tr-bar {
      background-color: #ff474733;
      right: 0;
      left: auto;
      font-size: 0.6875rem;
    }

    .background-volume {
      position: absolute !important;
      z-index: 1 !important;
      transition: all 0.3s ease-in-out;
    }

    .TableList {
      .ReactTable {
        .ob-th-price {
          padding-right: 0.5rem;
        }
        .ob-td-price {
          padding-right: 0rem;
          padding-left: 0.5rem;
          color: $color-sunset-orange;
        }

        .ob-td-count {
          color: $color-blue--dark-faint;
        }

        .ob-td-total {
          margin-left: 1rem;
        }
      }
    }
  }

  &__column--bids {
    .rt-tr-bar {
      background-color: #7ac13133;
    }

    .TableList {
      .ReactTable {
        .ob-td-price {
          color: $color-atlantis;
        }

        .ob-td-count {
          color: $color-blue--dark-faint;
        }
      }
    }
  }

  &__footer {
    display: flex;
    width: 100%;
    margin: 0;
    padding: 0 1rem;
  }

  &__footer-bids {
    flex: 1;
    text-align: left;
  }

  &__footer-asks {
    flex: 1;
    text-align: right;
  }

  .MarketPrice {
    background-color: $color-dark-shark;
    margin: 0 -21px 10px -21px;
    padding: 7px 10px;

    font-size: 16px;
    text-align: left;

    &__trading-pair {
      float: left;
    }

    .MarketSummary__price-change {
      float: right;
      margin: 0px;
      height: 20px;

      &::before {
        content: '';
        width: 20px;
        height: 20px;
        display: inline-block;
        position: relative;
        top: 2px;
      }

      &.change-down {
        color: #ff4747;
        &::before {
          transform: rotate(0);
        }
      }
    }
  }
}
