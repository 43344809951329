@import '~css/shared/variables';

.StepSelector {
  display: flex;
  padding: 0rem 0 0 0.5rem;

  &__options {
    flex: 1;
    margin: 0 1rem 0 0rem;
    display: flex;
    width: 100%;
    position: relative;
    justify-content: space-between;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    cursor: pointer;

    &::after {
      content: '';
      display: block;
      height: 1px;
      background: #2a2b31;
      position: absolute;
      left: 0rem;
      right: 0rem;
      top: 0.75rem;
    }

    &-selected {
      position: absolute;
      top: 0.75rem;
      z-index: 1;
      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 1px;
        top: 0;
      }
    }
  }

  &__option {
    margin: 0;
    padding: 1.5rem 0 0 0;
    border: none;
    background: none;
    flex: 0;
    text-align: center;
    background: none;
    color: $color-dark-abbey;
    font-weight: $font-weight--bold;
    font-size: 0.625rem;
    position: relative;
    cursor: pointer;
    display: flex;
    justify-content: center;
    width: 0;

    &:focus {
      outline: none;
    }

    &-label {
      margin-top: -2px;
    }

    &::after {
      content: '';
      display: block;
      width: 0.5rem;
      height: 0.5rem;
      background: #85858a;
      border-radius: 4rem;
      position: absolute;
      left: 50%;
      top: 0.5rem;
      margin-left: -0.25rem;
      z-index: 2;
    }
  }

  &__mark {
    position: absolute;
    width: 0;
    overflow: visible;
    top: 0.25rem;
    z-index: 2;
    &::after {
      content: '';
      width: 1rem;
      height: 1rem;
      left: -0.5rem;
      display: block;
      border-radius: 1rem;
      position: absolute;
      top: 0;
    }
  }

  &__field-container {
    font-size: 0.7rem;
    color: $color-blue--dark-faint;
    position: relative;
    &:after {
      content: '%';
      position: absolute;
      top: 0.3rem;
      font-size: 0.7rem;
      color: $color-blue--dark-faint;
      right: 0.7rem;
    }
  }

  &__field {
    width: 4rem;
    height: 1.5rem;
    font-size: 0.75rem !important;
    font-weight: $font-weight--bold;
    background: $color-blue--ultra-dark;
    border: 1px solid $color-blue--slate;
    padding: 0 1.2rem 0 0.4rem;
    color: $color-blue--dark-faint;
    border-radius: 0.125rem;
    -moz-appearance: textfield;
    text-align: center;
    &:focus {
      outline: none;
    }
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
    }
  }

  &--buy {
    .StepSelector__options-selected,
    .StepSelector__option-selected,
    .StepSelector__mark {
      &::after {
        background-color: $color-atlantis;
      }
    }
  }

  &--sell {
    .StepSelector__options-selected,
    .StepSelector__option-selected,
    .StepSelector__mark {
      &::after {
        background-color: $color-sunset-orange;
      }
    }
  }

  &--convert {
    .StepSelector__options-selected,
    .StepSelector__option-selected,
    .StepSelector__mark {
      &::after {
        background-color: $color-yellow;
      }
    }
  }
}
