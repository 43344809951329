@import '~css/shared/variables';

.NotificationsPublisher {
  position: fixed;
  right: 1.5em;
  top: 1.5em;
  z-index: 1000;

  &__Notification {
    border-radius: 4px;
    background: $color-blue--slate;
    color: white;
    width: 21rem;
    animation-name: fadeIn-fadeOut;
    transition: all 0.5s ease;
    animation-timing-function: linear;
    animation-duration: 5s;
    opacity: 0;
    max-height: 0;
    font-size: 0.6875rem;
    font-weight: $font-weight--bold;
    margin-bottom: 1rem;
    overflow: hidden;
    position: relative;

    &--close {
      position: absolute;
      right: 0.45rem;
      top: 0.45rem;
      cursor: pointer;
    }

    &--header {
      padding: 0.75rem 0.75rem 0.35rem;
      &--label {
        font-size: 0.625rem;
        background: $color-green;
        padding: 2px 5px;
        color: $color-blue--ultra-dark;
        border-radius: 2px;
        margin-right: 0.5rem;
        text-transform: capitalize;
        font-weight: $font-weight--bold;
      }
      &--title {
        text-transform: uppercase;
      }
    }

    &--body {
      padding: 0 0.75rem 0.75rem 0.75rem;
    }

    &--bar {
      height: 10px;
      width: 100%;
      background: $color-blue;
      animation-name: expandWidth;
      animation-duration: 5s;

      &.type-positive {
        background: $color-green;
      }
      &.type-negative {
        background: $color-red--pale;
      }
    }
  }
}

@keyframes fadeIn-fadeOut {
  0% {
    opacity: 0;
    max-height: 400px;
  }
  10% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  85% {
    max-height: 400px;
  }
  90% {
    opacity: 0;
  }
  100% {
    max-height: 0;
  }
}

@keyframes expandWidth {
  0% {
    width: 0%;
  }
  10% {
    width: 0%;
  }
  90% {
    width: 100%;
  }
  100% {
    width: 100%;
  }
}
