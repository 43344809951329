@import '~css/shared/variables';

.DialogPageRegister {
  &__dialog {
    .MuiPaper-root {
      width: 424px;
      margin: 0px;
      background: #2a2b31;
    }
  }

  &__title {
    background: #43444b;
    border-radius: 5px 5px 0px 0px;
    height: 56px;
    position: relative;
    padding: 11px 56px;
    text-align: center;
    font-weight: bold;
    font-size: 16px;
    line-height: 16px;
    color: #ffffff;
  }

  &__content {
    padding: 44px !important;

    .DialogPageRegister__actions {
      justify-content: center;
      padding: 0px;
      margin: 0px;

      .DialogPageRegister__actions__copyPrivateKey {
        height: 40px;
        background: #7ac231;
        border-radius: 2px;
        font-weight: bold;
        font-size: 16px;
        line-height: 16px;
        text-align: center;
        color: #ffffff;
        max-width: 100%;
        width: 110px;
        margin: 0px;
        padding: 12px;
        outline: none;
        border: none;
        text-transform: inherit;
      }

      .DialogPageRegister__actions__verityMnemonic {
        margin: 0px;
        outline: none;
        border: none;
        text-transform: inherit;
        height: 40px;
        width: 100%;
        background: #7ac231;
        border-radius: 2px;
        font-weight: bold;
        font-size: 16px;
        line-height: 16px;
        text-align: center;
        color: #ffffff;

        &:disabled {
          background: #626367;
          color: rgba(251, 252, 251, 0.5);
        }
      }

      .DialogPageRegister__actions__confirmCreateMnemonicNo {
        height: 40px;
        background: #ff4747;
        border-radius: 2px;
        font-weight: bold;
        font-size: 16px;
        line-height: 16px;
        text-align: center;
        color: #ffffff;
        max-width: 100%;
        width: 110px;
        margin: 0px;
        padding: 12px;
        outline: none;
        border: none;
        text-transform: inherit;
        margin: 0px 12px;
      }

      .DialogPageRegister__actions__confirmCreateMnemonicYes {
        height: 40px;
        background: #7ac231;
        border-radius: 2px;
        font-weight: bold;
        font-size: 16px;
        line-height: 16px;
        text-align: center;
        color: #ffffff;
        max-width: 100%;
        width: 110px;
        margin: 0px;
        padding: 12px;
        outline: none;
        border: none;
        text-transform: inherit;
        margin: 0px 12px;
      }

      .DialogPageRegister__actions__accessCreateMnemonic {
        margin: 0px;
        outline: none;
        border: none;
        text-transform: inherit;
        height: 40px;
        width: 100%;
        background: #7ac231;
        border-radius: 2px;
        font-weight: bold;
        font-size: 16px;
        line-height: 16px;
        text-align: center;
        color: #ffffff;
      }
    }
  }

  &__btn__close {
    position: absolute !important;
    top: 0px;
    right: 0px;
    width: 56px;
    height: 56px;
    box-shadow: none !important;
    outline: none !important;
    border: none !important;
  }

  &__dialog__privateKey {
    text-align: center;
    color: #ffffff;
    margin-bottom: 30px;

    .icon__access__create__mnemonic {
      color: #7ac231;
      font-size: 27px;
    }

    .title {
      font-weight: bold;
      font-size: 14px;
      line-height: 14px;
      color: #ffffff;
      margin: 0 0 11px 0;
    }

    .title_des {
      font-size: 12px;
      line-height: 22px;
      text-align: center;
      color: #ffffff;
      margin: 0 0 26px 0;
    }

    .value {
      width: 100%;
      background: #43444b;
      border-radius: 2px;
      height: 40px;
      font-size: 12px;
      line-height: 12px;
      color: #ffffff;
      padding: 14px 13px;
      text-align: left;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      outline: none;
      border: none;
    }
  }

  &__dialog__createMnemonic {
    color: #ffffff;

    .des {
      font-size: 12px;
      line-height: 22px;
      text-align: center;
      color: #ffffff;
      margin-bottom: 22px;
    }

    .list_random_memonic {
      display: flex;
      flex-wrap: wrap;
      margin: 0px -8px;
      margin-bottom: 22px;

      .item {
        background: #43444b;
        border-radius: 2px;
        width: calc((100% - 60px) / 3);
        margin: 0px 10px 16px 10px;
        height: 35px;
        font-size: 14px;
        line-height: 14px;
        color: #b0b0b0;
        display: block;
        align-items: center;
        padding: 10px 2px 10px 13px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        cursor: pointer;

        &:hover {
          background: #7ac131;
          color: #ffffff;
        }
      }
    }
  }

  &__dialog__createMnemonicPick {
    margin-bottom: 22px;

    .error {
      font-size: 12px;
      color: #ff4747;
      margin-top: 5px;
    }

    .list_random_memonic_pick {
      display: flex;
      flex-wrap: wrap;
      margin: 0px 0px;
      background-color: rgb(31, 37, 56);
      padding: 15px 5px;
      min-height: 130px;

      &.error_memonic_pick {
        border: 1px solid #ff4747;
      }

      .item {
        background: #43444b;
        border-radius: 2px;
        width: calc((100% - 60px) / 3);
        margin: 0px 10px 16px 10px;
        height: 35px;
        font-size: 14px;
        line-height: 14px;
        color: #b0b0b0;
        display: block;
        align-items: center;
        padding: 10px 2px 10px 13px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        cursor: pointer;

        &:hover {
          background: #7ac131;
          color: #ffffff;
        }
      }
    }
  }
}
