@import '~css/shared/variables';

.header-warning {
  background-color: #dc2556;
  text-align: center;
  display: block;
  height: 50px;
  padding: 12px;
  width: 100%;
}

.header {
  &__address,
  &__balance {
    background-color: #1e2024;
    border-radius: 2px;

    .Row {
      margin-bottom: 0px;
    }

    .left {
      padding: 22px 0px 25px 24px;
      display: flex;
      align-items: center;

      img {
        height: 48px;
        width: 48px;
        object-fit: cover;
      }

      .content {
        height: 48px;
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: space-between;
        margin-left: 20px;

        input {
          width: 100%;
          background-color: #1e2024;
          color: #ffffff;
          border: 0;

          &:focus {
            outline: 0;
          }
        }

        p,
        span {
          margin: 0;
          padding: 0;
          font-size: 16px;
          font-family: $font-family--regular;
          line-height: 16px;
          display: flex;
          align-items: center;

          .Spinner {
            margin-left: 10px;
          }
        }

        p {
          font-weight: 400;
          color: azure;
        }

        span {
          font-weight: 500;
          color: #fff;
        }
      }
    }

    .right {
      display: flex;
      justify-content: space-between;
      flex-direction: column;

      img {
        cursor: pointer;
      }

      .top {
        border-left: 0.1px solid #2a2b30;
        border-bottom: 0.1px solid #2a2b30;
        justify-content: center;
        align-items: center;
        display: flex;
        height: 50%;
        cursor: pointer;
      }

      .bottom {
        border-left: 0.1px solid #2a2b30;
        justify-content: center;
        align-items: center;
        display: flex;
        height: 50%;
        cursor: pointer;
      }
    }
  }
}

.modal-header-balance {
  width: 450px;
  border-radius: 6px;
  color: #ffffff;
  background-color: #2a2b31;

  .title-header-balance {
    padding: 20px;
    background-color: #43444b;
    border-radius: 2px;
    font-weight: 700;
    font-size: 18px;
    display: flex;
    align-items: center;
    img {
      margin-left: auto;
    }
  }

  .content-header-balance {
    padding: 20px;

    .content-row-header {
      display: flex;
      align-items: center;

      .content-row-value {
        margin-left: auto;
      }

      .content-row-label {
        opacity: 0.5;
      }
    }
  }
}

.qr-code-header {
  color: white;
  .qr-code-title {
    font-weight: 700;
    padding: 20px;
    font-size: 20px;
    text-align: left;
    background-color: #3b3c42;
    border-radius: 6px;
    color: #ffffff;
    display: flex;
    img {
      margin-left: auto;
    }
  }

  textarea {
    width: 100%;
    background-color: #2a2b31;
    color: #ffffff;
    border: 0;
    text-align: center;
    word-break: break-all;
    resize: none;

    &:focus {
      outline: 0;
    }
  }

  .qrcode-wrapper {
    margin-top: 20px;
  }

  .address-qrcode {
    padding: 20px 20px;
    word-break: break-all;
  }
  .copy-section {
    margin-bottom: 20px;
    font-size: 20px;
    color: $color-light-atlantis;
    cursor: pointer;
    font-weight: 700;
  }

  width: 400px;
  text-align: center;
  background-color: #2a2b31;
}
