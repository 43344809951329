@import '~css/shared/variables';

.InlineLabel {
  font-size: 0.625rem;
  background: rgba(122, 193, 49, 0.2);
  padding: 6px 10px;
  color: $color-atlantis;
  border-radius: 2px;
  margin-right: 0.5rem;
  display: inline-block;
  font-weight: $font-weight--bold;
  text-transform: capitalize;

  &--SELL,
  &--sell,
  &--negative {
    color: $color-sunset-orange;
    background: #ff474733;
  }
}
