@import 'shared/variables';
@import 'shared/reboot';

body {
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-family: $font-family--regular;
  font-weight: $font-weight--normal;
  -webkit-font-smoothing: antialiased;
}

@import 'shared/grid';
@import 'shared/animations';
@import 'shared/hacks';
@import 'shared/button';
@import 'shared/form';
@import 'shared/label';
@import 'shared/link';
@import 'shared/table';
@import 'shared/br';
@import 'shared/typography';
@import 'shared/note';
@import 'shared/scrollbars';
