@import '~css/shared/variables';

.MarketChart {
  background: $color-blue--ultra-dark;
  min-height: 460px;
  opacity: 0;
  transition: opacity 1s ease-in-out;

  &--is-ready {
    opacity: 1;
  }

  iframe {
    background: $color-blue--ultra-dark;
  }
}
