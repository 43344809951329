@import '~css/shared/variables';

.NumberInput {
  background: $color-dark-shark;
  border-radius: 2px;
  border: none;
  padding: 0.4rem 3rem 0.4rem 1rem;
  width: 100%;
  color: #85858a;
  border-radius: 0.125rem;
  -moz-appearance: textfield;

  &--with-spin {
    &.NumberInput--with-unit {
      padding-right: 5rem;
    }

    + .NumberInput__unit {
      right: 2rem;
    }
  }

  &--with-floating-label {
    padding-left: 3.5rem;
  }

  &:focus {
    background-color: $color-dark-mako;
  }

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }

  &:focus {
    outline: none;
  }

  &::-webkit-input-placeholder {
    color: $color-dark-mid-gray;
  }
  &::-moz-placeholder {
    color: $color-dark-mid-gray;
  }
  &:-moz-placeholder {
    color: $color-dark-mid-gray;
  }
  &:-ms-input-placeholder {
    color: $color-dark-mid-gray;
  }

  &__unit {
    position: absolute;
    right: 0.5rem;
    top: 0.4375rem;
    font-size: 0.75rem;
    pointer-events: none;
    color: $color-dark-mid-gray;
    font-weight: $font-weight--bold;
  }

  &__spin-button {
    position: absolute;
    right: 1px;
    top: 1px;
    bottom: 1px;
    width: 23px;
    color: $color-dark-mid-gray;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;

    &-up,
    &-down {
      position: absolute;
      top: 4px;
      left: 0px;
      right: 3px;
      height: 40%;
      background: url('../../assets/icons/icon-select.svg');
      text-align: center;
      cursor: pointer;
      font-size: 18px;

      &:hover {
        background-color: $color-dark-mako;
      }

      &:active {
        background-color: $color-dark-mako;
      }
    }

    &-down {
      top: 50%;
      border-bottom: 0;
      transform: rotate(180deg);
    }
  }

  &__floatingLabel {
    position: absolute;
    left: 0.625rem;
    top: 0.4375rem;
    pointer-events: none;
    color: $color-dark-mid-gray;
    font-size: 0.75rem;
    font-weight: $font-weight--normal;
    text-transform: capitalize;
  }
}
