@import '~css/shared/variables';

.Farming {
  &__input-wrapper {
    position: relative;
    display: inline-block;
    margin-bottom: 25px;
  }

  &__input-search {
    padding: 10px 15px;
    background-color: transparent;
    background: #2a2b31;
    border: 1px solid rgba(255, 255, 255, 0.1);
    box-sizing: border-box;
    border-radius: 2px;
    color: white;
    min-width: 300px;

    &::placeholder {
      color: #606166;
    }

    &:focus {
      outline: none;
    }
  }

  &__input-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
}
