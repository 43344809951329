@import '~css/shared/variables';

.custom-tabs {
  ul.react-tabs__tab-list {
    text-align: center;
    border-bottom: 1px solid $color-dark-shark;
    margin-bottom: 63px;
    .react-tabs__tab {
      font-size: 16px;
      line-height: 16px;
      color: rgba(255, 255, 255, 0.5);
      border: none;
      border-bottom: 2px solid transparent;
      box-shadow: none;
      outline: none;
      padding: 8px 0px;
      margin: 0px 18px;
    }
    .react-tabs__tab--selected {
      background: none;
      color: $color-white;
      border-bottom: 2px solid $color-atlantis;
    }
  }
  .input-login {
    position: relative;
    input {
      width: 100%;
      background: $color-dark-shark;
      padding: 20px 45px 20px 20px;
      height: 55px;
      border: none;
      border-radius: 6px;
      color: $color-white;
    }
    .icon__input {
      background-repeat: no-repeat;
      background-size: contain;
      position: absolute;
      top: 20px;
      right: 20px;
      width: 18px;
      height: 18px;
    }
    .icon__input__safe {
      background-image: url('../../../../assets/icons/safe.svg');
    }
    .icon__input__private__key,
    .icon__input__password {
      background-image: url('../../../../assets/icons/key.svg');
    }
  }
  .note__recommended {
    font-size: 12px;
    margin-top: 0px;
    margin-bottom: 14px;
    margin-top: 30px;
    color: $color-sunset-orange;
  }
  .image__metamask {
    text-align: center;
    margin-bottom: 3rem;
  }
  .PublicArea__MetaMask {
    &__intro {
      font-size: 14px;
      line-height: 26px;
      color: $color-white;
      text-align: center;
      .text-note-reno {
        color: $color-reno-sand;
      }
      .text-note-atlantis {
        color: $color-atlantis;
      }
    }
    &__recommended {
      font-size: 12px;
      margin-top: 0px;
      margin-bottom: 14px;
      text-align: center;
      color: $color-sunset-orange;
    }
  }
  .error-login {
    color: $color-sunset-orange;
    font-size: 14px;
    margin-bottom: 0px;
  }
}

.LoginPage__switchmode {
  margin-top: 28px;
  text-align: center;
  font-size: 14px;
  line-height: 14px;
  color: #7ac131;
  cursor: pointer;
}
