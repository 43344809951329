html ::-webkit-scrollbar {
  width: 0.625rem;
  background-color: transparent;
}

html ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0.375rem rgba(0, 0, 0, 0.15);
  background-color: #212529;
}

html ::-webkit-scrollbar-thumb {
  width: 0.625rem;
  border-radius: 0.625rem;
  box-shadow: inset 0 0 0.375rem rgba(0, 0, 0, 0.05);
  background-color: #43444b;
}
