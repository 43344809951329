@import '~css/shared/variables';

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: $background-color--canvas;

  &__left {
    background: $background-color--left;
    width: 90px;
    min-width: 90px;
    min-height: 100vh;
    position: fixed;
    left: 0;
    top: 0px;
    overflow-x: auto;
  }

  &__right {
    width: calc(100% - 90px);
    min-width: 1090px;
    margin-left: auto;
    padding: 36px;
    color: #fff;
  }

  &__content {
    flex: 1;
    color: #fff;
    display: flex;
    // fixed footer always bottom
    // 265 is same total height : header + footer
    min-height: calc(100vh - 265px);
  }

  &__footer {
    margin-top: 30px;
    border-top: 2px solid $background-color--left;
  }
}
