@import 'variables';

a,
.link--primary {
  &:link,
  &:visited {
    color: $link-primary;
    text-decoration: none;
  }
  &:hover,
  &:active {
    color: $link-primary--hover;
    text-decoration: none;
  }
}

.link--secondary {
  &:link,
  &:visited {
    color: $link-secondary;
    text-decoration: none;
  }
  &:hover,
  &:active {
    color: $link-secondary--hover;
    text-decoration: none;
  }
}
