@import '~css/shared/variables';

.confirm-remove-modal {
  background: #2a2b31;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
  width: 400px;
  color: #ffffff;
  border-radius: 8px;

  .confirm-remove-title {
    font-weight: 700;
    padding: 20px;
    font-size: 20px;
    text-align: center;
    background-color: #3b3c42;
    border-radius: 6px;

    .btn-icon-close {
      cursor: pointer;
    }
  }

  .confirm-modal-content {
    padding: 20px;
    .plus-icon-row {
      text-align: left;
      margin-top: 10px;
      margin-bottom: 10px;
    }

    .confirm-modal-row {
      display: flex;

      .confirm-row-amount {
        font-weight: 700;
        font-size: 18px;
      }

      .confirm-row-label {
        margin-left: auto;
        text-align: right;
        opacity: 0.7;
        img {
          width: 20px;
          margin-right: 10px;
        }
      }
    }
    .warning-text {
      font-size: 14px;
      text-align: center;
      margin-top: 10px;
      color: #606166;
      margin-bottom: 10px;
    }
    .box-information {
      padding: 10px;
      border: 1px solid #585a61;
      border-radius: 6px;
    }
    .box-information-row {
      display: flex;
      line-height: 30px;

      .box-information-label {
        font-size: 14px;
        color: #ffffff;
        opacity: 0.7;
      }
      .box-information-value {
        margin-left: auto;
        text-align: right;
        img {
          width: 20px;
          margin-right: 10px;
        }
      }
    }
    .button-confirm {
      margin-top: 20px;
      button {
        width: 100%;
      }
    }
  }
}
