@import 'variables';

$button-background: $color-atlantis;
$button-background--hover: $color-green--light;

.button {
  display: inline-block;
  background: $button-background;
  font-weight: $font-weight--bold;
  padding: 0.75rem 2rem;
  border-radius: 0.125rem;
  border: 1px solid $button-background;
  cursor: pointer;
  text-decoration: none;
  color: white;

  &:not([disabled]) {
    &,
    &:link,
    &:visited {
      color: #fff;
      text-decoration: none;
    }

    &:focus,
    &:hover {
      background: $button-background--hover;
      border: 1px solid $button-background;
      outline: none;
    }

    &:active {
      color: #fff;
      background: $button-background--hover;
      border: 1px solid $button-background--hover;
      outline: none;
    }
  }

  &--uppercase {
    text-transform: uppercase;
  }

  &--faint {
    &:not([disabled]) {
      background: none;
      border: none;
      padding-left: 0;
      &,
      &:link,
      &:visited {
        color: $color-blue--dark-faint;
      }
      &:hover {
        color: lighten($color-blue--dark-faint, 20);
        border: none;
        background: none;
      }
    }
  }

  &--left {
    float: left;
  }

  &--block {
    display: block;
    width: 100%;
  }

  &[disabled] {
    opacity: 0.7;
    cursor: not-allowed;
  }

  &--small {
    font-size: 12px;
    font-weight: $font-weight--bold;
    color: $color-blue--text;
    padding: 6px 12px;
    line-height: 1;
  }

  &--uppercase {
    text-transform: uppercase;
  }

  &--block {
    display: block;
    width: 100%;
  }
}
