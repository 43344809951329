@import '~css/shared/variables';

.AppHeader {
  display: flex;
  align-items: center;
  position: relative;
  margin-top: 6px;
  margin-bottom: 18px;

  &__title_page {
    font-weight: 600;
    font-size: 24px;
    line-height: 24px;
    margin: 0;
  }

  &__account {
    margin-left: auto;
    display: flex;

    .btn--support {
      color: $color-white;
      text-decoration: none;
      background-color: $color-dark-shark;
      margin-right: 18px;
      font-size: 12px;
      padding: 8px 12px;
      height: 35px;
      display: flex;
      align-items: center;

      .icon--support {
        mask-image: url('../../assets/icons/icon-support.svg');
        background-color: $color-dark-mid-gray;
        display: inline-block;
        width: 14px;
        height: 14px;
        margin-right: 10px;
      }
    }
  }

  &__opensea_button {
    color: rgba(255, 255, 255, 0.85);
    cursor: pointer;
    height: 35px;
    width: 120px;
    display: flex;
    padding: 10px;
    overflow: hidden;
    font-size: 12px;
    background: rgba(98, 99, 103, 0.5);
    transition: all 0.2s ease 0s;
    align-items: center;
    font-weight: bold;
    line-height: 12px;
    border-radius: 2px;
    justify-content: center;
    margin-right: 15px;
  }
}
