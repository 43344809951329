.MuiDialogTitle-root {
  background: #43444b;
  border-radius: 5px 5px 0px 0px;
  text-align: center;
  color: white;
  font-weight: 700;
  font-size: 16px;
  border: none;
}

.MuiDialog-paper {
  border-radius: 5px !important;
  background-color: #2a2b31 !important;
  min-width: 420px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5) !important;
}

.MuiDialogActions-root {
  display: flex !important;
  justify-content: center !important;
  margin-top: 35px;
}

.Dialog {
  &__title {
    position: relative;
  }
  &__icon {
    position: absolute !important;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    color: white !important;

    &:focus {
      outline: none;
    }
  }

  &__btn {
    padding: 10px 50px;
    border-radius: 2px;
    font-weight: 700;
    border: none;
    cursor: pointer;
    display: inline-block;
    font-size: 16px;

    &:disabled {
      background-color: #2c3f17;
    }

    &:focus {
      outline: none;
    }

    &--cancel {
      background: #626367;
      color: #aeafb0;
    }

    &--confirm {
      background: #7ac231;
      color: white;
      margin-left: 25px !important;
    }
  }
}
