@import '~css/shared/variables';

$borderColor: #2a2b30;
$backgroundColor: #1e2024;
$colorGreen: #7ac231;
$borderColor: #2a2b30;
$backgroundButtonGray: #3b3c42;
$textColorGray: #606166;

.add-liquidity-container {
  padding: 20px;
  background-color: #1e2024;

  .form-icon-help {
    display: flex;
    justify-content: flex-end;
    .question-mark {
      margin-right: 10px;
    }
    .setting-circle {
      cursor: pointer;
    }
    .setting-wrapper {
      position: relative;
    }
  }

  .hidden {
    display: none;
  }

  .form__row {
    padding: 20px;
    border: 1px solid $color-shark;

    .form-row-input {
      display: flex;
      margin-top: 5px;

      .approve-button {
        margin-left: 20px;
        min-width: 150px;

        &:disabled {
          background-color: gray !important;
          border: 0;
        }
      }
    }

    .form-row-label {
      display: flex;
      font-size: 12px;
      line-height: 12px;
      color: $textColorGray;
    }

    .balance-stake {
      text-align: right;
      margin-left: auto;
    }

    .field-group {
      display: flex;
      width: 70%;

      .max-button {
        background-color: $backgroundButtonGray !important;
        color: $colorGreen;
        border: 0;
        min-width: 70px;
      }

      /* Chrome, Safari, Edge, Opera */
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      input[type='number'] {
        -moz-appearance: textfield;
      }
    }

    .display-token-name {
      width: 150px;
      border-radius: 2px;
      background-color: #2a2b31;
      padding: 10px 10px 10px 14px;
      display: flex;
      margin-left: auto;
      align-items: center;
      color: white;
      cursor: pointer;
      position: relative;

      img {
        height: 14px;
        width: auto;
        object-fit: cover;
        margin-right: 5px;
      }

      .icon {
        width: 10px;
        height: 5px;
        align-items: flex-end;
        right: 10px;
        position: absolute;
        top: 12px;

        img {
          height: 100%;
          width: 100%;
        }
      }
    }
  }

  .plus-icon-row {
    text-align: center;
  }

  .price-share-pool {
    font-size: 14px;
    margin-top: 20px;
    .price-share-title {
      color: #ffffff;
      opacity: 0.5;
      text-align: center;
    }
    .price-share-container {
      display: flex;
      justify-content: center;
      margin-top: 10px;
      .price-share-column {
        padding: 10px 30px;
        text-align: center;
      }
      .price-share-value {
        opacity: 0.7;
      }
      .price-share-label {
        opacity: 0.3;
      }
      .border-right {
        border-right: 1px solid $borderColor;
      }
    }
  }

  .button-container {
    margin-top: 20px;
    text-align: center;
    button {
      &:disabled {
        background-color: gray !important;
        border: 0;
      }
    }
  }

  .box-position-bottom {
    padding: 10px;
    border: 1px solid $borderColor;
    margin-top: 20px;
    .position-box-title {
      font-size: 18px;
      font-weight: 500;
      color: #ffffff;
    }
    .position-box-row {
      margin-top: 10px;
      display: flex;
      .position-label {
        opacity: 0.5;
        width: 50%;
      }
      .image-container {
        display: flex;
        align-items: center;
        img {
          width: 25px;
          margin-right: 10px;
        }
      }
      .position-value {
        opacity: 0.7;
        width: 50%;
        text-align: right;
      }
    }
  }
}
