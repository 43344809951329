@import '~css/shared/variables';

$ml-input-text-color: $color-blue--quite-dark;
$ml-input-text-color--focus: $color-blue--dark-faint;

.MarketsList {
  &__input {
    margin: 17px 0;
    width: 100%;
    background: $color-dark-shark url('search.svg') 98% 50% no-repeat;
    background-size: 20px 20px;
    border: none;
    border-radius: 0.125rem;
    padding: 7px 12px;
    color: $color-dark-mid-gray;
    font-size: 14px;
    line-height: 20px;
    font-weight: $font-weight--normal;

    &::-webkit-input-placeholder {
      color: $color-dark-mid-gray;
    }
    &::-moz-placeholder {
      color: $color-dark-mid-gray;
    }
    &:-moz-placeholder {
      color: $color-dark-mid-gray;
    }
    &:-ms-input-placeholder {
      color: $color-dark-mid-gray;
    }

    &:focus {
      outline: none;
      border-color: $color-blue--dark;
      color: $color-dark-mid-gray;
    }
  }

  .TableList .ReactTable .rt-tbody .rt-td,
  .TableList .ReactTable .rt-thead .rt-th {
    &:last-child {
      flex: 56 0 auto !important;
      width: 68px !important;
    }
  }

  &__filter {
    padding: 0 0 0 0;

    .Select--single {
      .Select-control {
        .Select-value {
          padding: 5px 12px;
          .Select-value-label {
            color: $color-dark-abbey !important;
          }
        }

        .Select-input {
          &:focus {
            background-color: $color-dark-shark;
          }
        }
      }

      .Select-menu-outer {
        margin-top: 2px;
        background-color: $color-dark-shark;
      }
    }
  }

  // had to apply ugly cell heading shift,
  // because scrollbar in table is pushing body columns colimns a bit
  &__list--scrollable {
    margin-right: -0.75rem;

    .ReactTable {
      .rt-thead {
        .rt-th {
          position: relative;
          &:nth-child(2) {
            left: -3px;
          }
          &:nth-child(3) {
            left: -7px;
          }
          &:nth-child(4) {
            left: -9px;
          }
        }
      }
      .rt-overflow.rt-tbody {
        overflow: hidden;

        .rt-tr-group {
          padding-right: 0.75rem;
        }
      }

      .rt-overflow.rt-tbody:hover {
        overflow-y: overlay;
      }

      .rt-overflow .rt-tr-group .rt-td {
        overflow-x: hidden;
      }
    }
  }

  .rt-noData {
    position: absolute;
    top: 2.5rem;
    left: 0;
    text-align: center;
  }
}
