@import '~css/shared/variables';

.remove-liquidity-container {
  padding: 20px;
  background-color: $background-color--left;

  .form-icon-help {
    display: flex;
    justify-content: flex-end;

    .question-mark {
      margin-right: 10px;
    }

    .setting-circle {
      cursor: pointer;
    }

    .setting-wrapper {
      position: relative;
    }
  }

  .form-row {
    padding: 20px;
    border: 1px solid $color-shark;
    margin-top: 10px;
    .form-row-label {
      display: flex;
      font-size: 12px;
      line-height: 12px;
      color: $color-dark-mid-gray;
      .detail-label {
        margin-left: auto;
        color: $color-light-atlantis;
        cursor: pointer;
      }
      input {
        background: none;
        border: 0;
      }
    }
    .form-row-percent {
      font-size: 24px;
      font-weight: 700;
    }
    .slider-row {
      margin-top: 10px;
      .slider-range {
        $circleWidth: 20px;
        width: 100%;
        background: $color-dark-mid-gray;
        height: 10px;
        border-radius: 4px;
        -webkit-appearance: none;
        &::-webkit-slider-thumb {
          -webkit-appearance: none;
          appearance: none;
          width: $circleWidth;
          height: $circleWidth;
          border-radius: 16px;
          cursor: pointer;
          background: $color-light-atlantis;
        }
        &::-moz-range-thumb {
          -webkit-appearance: none;
          appearance: none;
          width: $circleWidth;
          height: $circleWidth;
          border-radius: 16px;
        }
        &:focus {
          outline: 0;
        }
      }
    }

    .form-button-group {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-top: 20px;
      button {
        background-color: #25262b;
        color: white;
        outline: 0;
        border: 0;
        margin: 5px;
        padding: 10px;
        width: 125px;
        span {
          opacity: 0.5;
        }
      }
      .active {
        color: $color-light-atlantis;
        background-color: $color-dark-mid-gray;
        span {
          opacity: 1;
        }
      }
    }
    .form-row-data {
      display: flex;
      .form-row-token {
        margin-left: auto;
        display: flex;
        min-width: 100px;
        align-items: center;
        text-align: right;
        justify-content: flex-end;
        img {
          width: 30px;
          margin-right: 20px;
        }
        span {
          min-width: 100px;
        }
      }
    }
  }
  .mt-10 {
    margin-top: 10px;
  }

  .line-down-arrow {
    margin-top: 20px;
    text-align: center;
    img {
      width: 16px;
    }
  }

  .price-share-pool {
    font-size: 14px;
    margin-top: 20px;
    .price-share-title {
      color: #ffffff;
      opacity: 0.5;
      text-align: center;
    }
    .price-share-container {
      display: flex;
      justify-content: center;
      margin-top: 10px;
      .price-share-column {
        padding: 10px 30px;
        text-align: center;
        min-width: 40%;
      }
      .price-share-value {
        opacity: 0.7;
      }
      .price-share-label {
        opacity: 0.3;
      }
      .border-right {
        border-right: 1px solid $color-shark;
      }
    }
  }

  .approve-button-group {
    margin-top: 20px;
    text-align: center;
    .approve-btn {
      margin-right: 10px;
      width: 250px;
      &:disabled {
        background-color: gray !important;
        border: 0;
      }
    }
    .remove-btn {
      margin-left: 10px;
      border: 0;
      width: 250px;
      &:disabled {
        background-color: gray !important;
        border: 0;
      }
    }
  }

  .box-position-bottom {
    padding: 10px;
    border: 1px solid $color-shark;
    margin-top: 20px;
    .position-box-title {
      font-size: 18px;
      font-weight: 500;
      color: #ffffff;
    }
    .position-box-row {
      margin-top: 10px;
      display: flex;
      .position-label {
        opacity: 0.5;
        width: 50%;
      }
      .image-container {
        display: flex;
        align-items: center;
        img {
          width: 25px;
          margin-right: 10px;
        }
      }
      .position-value {
        width: 50%;
        text-align: right;
      }
    }
  }
}
