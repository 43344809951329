$greenTextColor: #7ac231;

.amount {
  width: 100%;
  height: 40px;
  background-color: #2a2b31;
  color: #ffffff;
  border: none;
  font-weight: 400;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.label-form {
  color: #818284;
  font-size: 14px;
  //margin-bottom: 0 !important;
}

.flex-double-label {
  display: flex;

  .second-label {
    color: $greenTextColor;
    margin-left: auto;
    font-size: 14px;
  }
}

.to-address-input-group {
  position: relative;

  img {
    position: absolute;
    width: 20px;
    top: 15px;
    left: 20px;
    z-index: 2;
  }

  input {
    padding-left: 50px;
  }
}

.select-wrapper {
  position: relative;
}

.select-wrapper::after {
  content: url('../../assets/swap/down_icon.svg');
  font-size: 1rem;
  top: 10px;
  right: 10px;
  position: absolute;
}

select.form__select_ta {
  -webkit-appearance: none;
  appearance: none;

  &:focus,
  &:-webkit-autofill:focus {
    color: #ffffff !important;
    -webkit-text-fill-color: #ffffff;
  }

  color: #ffffff;
  padding-left: 15px;

  & option {
    color: #ffffff;

    &:hover {
      background-color: #38393c !important;
    }
  }
}

.clear-all-button {
  color: $greenTextColor;
  margin-top: 20px;
  text-align: center;
  font-size: 14px;
  justify-content: center;
}

input.form__input:focus {
  caret-color: #ffffff !important;
}

input.form__input {
  caret-color: #fff !important;
}

.search-button-group {
  display: flex;

  img {
    margin-left: -40px;
  }

  input {
    padding-right: 50px;
    margin-top: 5px;
  }
}

.type {
  background-color: antiquewhite;
  width: 100%;
  height: 40px;
  background-color: #2a2b31;
  color: #ffffff;
  border: none;
  font-weight: 400;
}

.btn-add-token {
  border: none;
  padding: 0 10px;
  background: none;
  color: #7ac231;
  font-size: 12px;
  margin-left: auto;
  cursor: pointer;

  &:focus {
    outline: none;
  }
}

.input-add-token {
  width: 100%;
  padding-right: 20px;
}

.btn-icon {
  color: #7ac231;
}

.row-send {
  margin-top: 35px;
}

.btn-send-disable {
  color: #6c7284;
  font-size: 16px;
  font-weight: 700;
  margin: 0 auto;
  background-color: #40444f;
  width: 211px;
  height: 40px;
  border-radius: 2px;
  cursor: pointer;
  border: none;
  display: block;

  &:focus {
    border: 0;
    outline: 0;
  }
}

.btn-send {
  color: #ffffff;
  font-size: 16px;
  font-weight: 700;
  margin: 0 auto;
  background-color: #7ac231;
  width: 211px;
  height: 40px;
  border-radius: 2px;
  cursor: pointer;
  border: none;

  &:focus {
    border: 0;
    outline: 0;
  }

  &:active {
    background-color: #687657;
  }
}

.btn-icon-close {
  float: right;
  cursor: pointer;
}

.btn-save-token {
  color: #ffffff;
  font-size: 16px;
  font-weight: 700;
  margin: 0 auto;
  background-color: #7ac231;
  width: 211px;
  height: 40px;
  border-radius: 2px;
  border: none;
  display: block;
  margin-top: 35px;
}

.add-token {
  justify-content: space-between;
  display: flex;
}

.label-modal {
  color: #fff;
}

.head {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

.list-token {
  //margin-left: -45px;
  //margin-right: -45px;
  .item-token {
    cursor: pointer;
    display: flex;

    &:hover {
      background-color: #38393c;
    }

    .container {
      display: flex;
      padding: 9px 45px 9px 15px;
      position: relative;
      width: 100%;
    }

    &__logo {
      width: 20px;
      height: 20px;
      margin-right: 10px;

      img {
        object-fit: contain;
        width: 100%;
        height: 100%;
      }
    }

    .wraper {
      display: flex;
      flex-direction: column;
    }

    &__symbol {
      color: #fff;
      font-size: 14px;
      line-height: 14px;
      margin-bottom: 5px;
    }

    &__name {
      color: #606166;
      font-size: 12px;
      line-height: 12px;
    }

    &__amount-token {
      line-height: 14px;
      font-size: 14px;
      position: absolute;
      right: 45px;
      color: #fff;
    }
  }
}

.modal_confirm {
  width: 450px;
  height: 640px;
  background-color: #2a2b31;
  border-radius: 5px;

  .mt-20 {
    margin-top: 20px;
  }

  .btn-send {
    margin-top: 20px;
    display: block;
    width: 100%;
  }

  .down-arrow {
    color: #7ac231;
    font-size: 30px;
    font-weight: 700;
  }

  .modal-content {
    padding: 20px;

    .box-form {
      padding: 20px 10px;
      border-radius: 2px;
      background-color: #35363c;

      .box-form-value {
        font-size: 24px;
        line-height: 30px;
        color: #ffffff;
        font-weight: 500;
      }

      .title-from-address {
        font-size: 14px;
        color: #ffffff;
      }

      .user-address {
        font-size: 12px;
        color: #606166;
      }
    }

    .box-information {
      padding: 10px;
      border: 1px solid #43444b;
      font-size: 14px;
      margin-top: 20px;
      border-radius: 2px;
      color: #ffffff;

      .title-box-information {
        font-weight: 700;
      }

      .row-box-information {
        display: flex;
        align-items: center;
        margin-top: 10px;

        .row-box-label {
          width: 50%;
          opacity: 0.7;
        }

        .row-box-value {
          width: 50%;
          text-align: right;
        }
      }
    }
  }

  .title_confirm {
    height: 56px;
    border-radius: 5px 5px 0px 0px;
    background-color: #43444b;
    font-weight: 500;
    color: #ffffff;
    font-size: 16px;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .from-address {
    font-size: 25px;
    color: #ffffff;
  }
}

.check-etherscan {
  text-align: center;
  color: #7ac231;
  font-size: 14px;
  margin-top: 20px;
}
.Select-clear-zone {
  display: none;
}

.Select--moon input {
  color: white;
  caret-color: white;
  margin-top: 5px;
}
