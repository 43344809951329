@import '~css/shared/variables';

.detail-remove-liquidity {
  .ml-auto {
    margin-left: auto;
  }

  .text-value {
    font-size: 14px;
    color: #ffffff;
  }

  .mt-10 {
    margin-top: 10px;
  }

  .pair-wrapper-sushi {
    img {
      width: 20px;
      margin-right: 10px;
    }
  }

  .form-row-sushi {
    padding: 20px;
    border: 1px solid $color-shark;
    margin-top: 10px;
    display: flex;
    align-items: center;

    .form-row-label {
      width: 70%;
      .amount-label {
        color: $color-dark-mid-gray;
        font-size: 12px;
        line-height: 12px;
      }
      input {
        background: none;
        border: 0;
      }
    }
  }

  .display-token-name {
    width: 150px;
    border-radius: 2px;
    background-color: #2a2b31;
    padding: 10px 10px 10px 14px;
    display: flex;
    margin-left: auto;
    align-items: center;
    color: white;
    margin-top: 12px;
    cursor: pointer;
    position: relative;

    img {
      height: 14px;
      width: auto;
      object-fit: cover;
      margin-right: 5px;
    }

    .icon {
      width: 10px;
      height: 5px;
      align-items: flex-end;
      right: 10px;
      position: absolute;
      top: 12px;

      img {
        height: 100%;
        width: 100%;
      }
    }
  }
}
