@import '~css/shared/variables';

.setting-transection {
  font-family: $font-family--regular;
  position: absolute;
  background: #fff;
  top: 34px;
  right: 0px;
  z-index: 10;
  background: #2a2b31;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  width: 350px;
  padding: 30px;
  .title {
    color: #fff;
    font-weight: $font-weight--extra-bold;
    line-height: 16px;
    font-size: 16px;
    letter-spacing: 0.4px;
  }
  .transection-title {
    font-weight: $font-weight--normal;
    font-size: 12px;
    color: #fff;
    line-height: 30px;
    margin-top: 15px;
    img {
      margin-left: 9px;
    }
  }
  .list-percent {
    display: flex;
    margin-top: 5px;
    .item {
      cursor: pointer;
      margin-right: 10px;
      padding: 10px;
      color: #fff;
      font-size: 14px;
      line-height: 14px;
      font-weight: $font-weight--normal;
      background-color: #43444b;
      border-radius: 2px;
      min-width: 65px;
      text-align: center;
    }
    .item.active {
      background-color: #7ac231;
    }

    .input-slippage {
      width: 65px;
      height: 34px;
      display: flex;
      align-items: center;
      position: relative;

      input {
        border: none;
        height: 100%;
        width: 100%;
        background-color: #43444b;
        border-radius: 2px;
        color: #fff;
        padding: 6px;
        padding-left: 10px;
        padding-right: 28px;
        &:focus {
          outline: none;
        }
      }
      span {
        position: absolute;
        right: 16px;
      }
    }
    .input-slippage-active {
      box-sizing: border-box;
      border: 1px solid #fff;
      border-radius: 2px;
    }
  }
  .slip-text {
    font-size: 14px;
    line-height: 14px;
    font-weight: $font-weight--normal;

    margin-top: 15px;
    font-weight: $font-weight--normal;
    &--warning {
      color: rgb(243, 132, 30);
    }
    &--error {
      color: red;
    }
  }
  .dealine-input {
    display: flex;
    align-items: center;
    margin-top: 5px;
    padding-bottom: 18px;
    border-bottom: 1px solid #ffffff1a;
    margin-bottom: 20px;

    input {
      background-color: #43444b;
      height: 35px;
      width: 65px;
      text-align: center;
      margin-right: 10px;
      border-radius: 2px;
      border: none;
      font-size: 14px;
      color: #fff;
      &:focus {
        outline: none;
      }
    }
    span {
      color: #606166;
      font-size: 12px;
      line-height: 30px;
    }
  }
  .on_off_setting {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    .item {
      display: flex;
      justify-content: space-between;
      margin-bottom: 5px;
      line-height: 30px;
      font-size: 12px;
      font-weight: $font-weight--normal;
      img {
        margin-left: 10px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .text-switch {
    font-family: $font-family--regular;
    font-size: 10px;
    color: #fff;
    line-height: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-weight: $font-weight--bold;
  }
}
